

import api from "../api"

export const getTimeZone = async () => {
    try {
        const response = await api.get('/getTimeZone');
        return response.data;
    }
    catch (error) {
        console.error('Erro ao buscar o timeZone:', error);
        throw error;
    }
};
