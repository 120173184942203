import React, { useEffect, useState } from 'react';
import '../assets/styles/sideMenu.css';
import { dadosUsuario } from '../utils/auth';
import ModalConfirmacaoAcao from './modais/ModalConfirmacaoAcao';
import { useNavigate, NavLink } from 'react-router-dom';
import { IconAgenda, IconBarbeiros, IconClientes, IconServicos, IconUsuarios } from '../assets/Icons';
import logo from '../assets/images/LogoCortae.png';
import logoCasaBrasil from '../assets/images/CasaBrasilLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import Carregamento from './Carregamento';

const SideMenu = () => {
  const [modalConfirmacaoLogoutAberto, setModalConfirmacaoLogoutAberto] = useState(false);
  const redirecionar = useNavigate();
  const [nome, setNome] = useState('Nome não disponível');
  const [funcao, setFuncao] = useState('Função não disponível');
  const [userRole, setUserRole] = useState(null);
  const [menuVisivel, setMenuVisivel] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDados = async () => {
      try {
        const dados = await dadosUsuario();
        const nomeUsuarioData = dados.nome;
        const funcaoUsuarioData = dados.funcao;
        setNome(nomeUsuarioData || 'Nome não disponível');
        setFuncao(funcaoUsuarioData || 'Função não disponível');
        if (funcaoUsuarioData==='Administrador') {
          setUserRole('Admin');
        } else if (funcaoUsuarioData==='Cliente') {
          setUserRole('Cliente');
        } else if (funcaoUsuarioData==='Barbeiro') {
          setUserRole('Barbeiro');
        }
        // await new Promise((resolve) => setTimeout(resolve, 600));

      } catch (error) {
        console.error("Erro ao buscar dados do usuário:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDados();
  }, []);

  const cancelarLogout = () => {
    setModalConfirmacaoLogoutAberto(false);
  };

  const confirmacaoLogout = () => {
    localStorage.removeItem('usuarioAtual');
    localStorage.removeItem('token');
    setModalConfirmacaoLogoutAberto(false);
    redirecionar('/login');
  };

  const toggleMenu = () => {
    if (window.innerWidth < 1024) {
      setMenuVisivel(prevState => !prevState);
      const sideMenu = document.querySelector('.divPrincipalSideMenu');
      if (!menuVisivel) {
        sideMenu.style.transform = 'translateX(0)';
        sideMenu.style.opacity = '1';
      } else {
        sideMenu.style.transform = 'translateX(-100%)';
        sideMenu.style.opacity = '0';
      }
    }
  }


  const menuLinks = [
    {
      path: '/gerenciar/agendamentos',
      label: 'Agenda',
      icon: IconAgenda,
    },
    {
      path: '/gerenciar/barbeiros',
      label: 'Barbeiros',
      icon: IconBarbeiros,
    },
    {
      path: '/gerenciar/clientes',
      label: 'Clientes',
      icon: IconClientes,
    },
    {
      path: '/gerenciar/servicos',
      label: 'Serviços',
      icon: IconServicos,
    },
    {
      path: '/gerenciar/usuarios',
      label: 'Usuários',
      icon: IconUsuarios,
    },
  ];

  const menuLinksCliente = [
    {
      path: '/minha-conta/meus-agendamentos',
      label: 'Agendamentos',
      icon: IconAgenda,
    },
  ];

  if (loading) {
    return <Carregamento />;
  }

  return (
    <>
      <ModalConfirmacaoAcao
        isOpen={modalConfirmacaoLogoutAberto}
        titulo="Sair?"
        mensagem="Você tem certeza que deseja sair?"
        acaoCancelar={cancelarLogout}
        acaoConfirmar={confirmacaoLogout}
      />
      <div className={`divPrincipalSideMenu ${menuVisivel ? 'visible' : 'hidden'}`}>
        <div className='topSideMenu'>
          <img src={logoCasaBrasil} alt="Logo" />
        </div>
        <div className='middleSideMenu'>
          {userRole === 'Admin' && (
            <nav className='itensMenu'>
              {menuLinks.map((link) => (
                <NavLink
                  key={link.path}
                  to={link.path}
                  style={({ isActive }) => ({
                    stroke: isActive ? 'var(--dourado)' : 'white',
                    color: isActive ? 'var(--dourado)' : 'white',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center'
                  })}
                  onClick={toggleMenu}
                >
                  <span style={{ marginRight: '8px' }}>{link.icon}</span>
                  <p>{link.label}</p>
                </NavLink>
              ))}
            </nav>
          )}
          {userRole === 'Cliente' && (
            <nav className='itensMenu'>
              {menuLinksCliente.map((link) => (
                <NavLink
                  key={link.path}
                  to={link.path}
                  style={({ isActive }) => ({
                    stroke: isActive ? 'var(--dourado)' : 'white',
                    color: isActive ? 'var(--dourado)' : 'white',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center'
                  })}
                  onClick={toggleMenu}
                >
                  <span style={{ marginRight: '8px' }}>{link.icon}</span>
                  <p>{link.label}</p>
                </NavLink>
              ))}
            </nav>
          )}
          <div onClick={() => setModalConfirmacaoLogoutAberto(true)} className='divSair'>
            <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ marginRight: '8px', height: '25px' }} />
            <p>Sair</p>
          </div>
          <div className='userInfoCard'>
            <FontAwesomeIcon icon={faUser} />
            <div className='nomeEFuncao'>
              <p>{nome}</p>
              <p className='funcao'>{funcao}</p>
            </div>
          </div>
        </div>
        <div className='bottomSideMenu'>
          <img src={logo} alt="Logo" />
        </div>
      </div>
    </>
  );
};

export default SideMenu;
