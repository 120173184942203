import { useEffect, useState } from "react";
import api from "../../services/api";
import '../../assets/styles/horariosAtendimento.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import ModalSucesso from '../modais/ModalSucesso';

const formatarParaInput = (dataHora) => {
  const data = new Date(dataHora);
  const ano = data.getFullYear();
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const dia = String(data.getDate()).padStart(2, '0');
  const horas = String(data.getHours()).padStart(2, '0');
  const minutos = String(data.getMinutes()).padStart(2, '0');

  return `${ano}-${mes}-${dia}T${horas}:${minutos}`;
};

const formatarParaBackend = (dataHora) => {
  const [data, hora] = dataHora.split('T');
  return `${data} ${hora}:00`;
};

const HorarioBarbeiro = ({ textoBotao, barbeiroId }) => {
  const [restricoesHorarios, setRestricoesHorarios] = useState([]);
  const [modalSucessoCadastroAberto, setModalSucessoCadastroAberto] = useState(false);

  useEffect(() => {
    const fetchRestricoes = async () => {
      try {
        const response = await api.post(`/restricoesHorarioBabreiro`, {
          barbeiroId,
        });
        const restricoesFormatadas = response.data.map(restricao => ({
          ...restricao,
          inicio: formatarParaInput(restricao.inicio),
          fim: formatarParaInput(restricao.fim),
        }));
        setRestricoesHorarios(restricoesFormatadas);
      } catch (error) {
        console.error("Erro ao carregar as restrições de horários:", error);
      }
    };
    fetchRestricoes();
  }, [barbeiroId]);

  const alterarRestricao = (index, field, value) => {
    const restricoesAtualizadas = restricoesHorarios.map((restricao, i) =>
      i === index ? { ...restricao, [field]: value } : restricao
    );
    setRestricoesHorarios(restricoesAtualizadas);
  };

  const adicionarNovaRestricao = () => {
    setRestricoesHorarios((prevRestricoes) => [
      ...prevRestricoes,
      { inicio: "", fim: "", ativo: false },
    ]);
  };

  console.log('restricoes', restricoesHorarios);

  const removerRestricao = (index) => {
    setRestricoesHorarios((prevRestricoes) =>
      prevRestricoes.filter((_, i) => i !== index)
    );
  };

  const registrar = async (e) => {
    e.preventDefault();
    try {
      const restricoesFormatadas = restricoesHorarios.map(restricao => ({
        ...restricao,
        inicio: formatarParaBackend(restricao.inicio),
        fim: formatarParaBackend(restricao.fim),
      }));

      await api.post("/cadastrarOuAtualizarRestricoesHorarioBarbeiro", {
        barbeiroId,
        restricoesHorarios: restricoesFormatadas,
      });
      setModalSucessoCadastroAberto(true);
    } catch (error) {
      console.error("Erro ao registrar as restrições de horários:", error);
    }
  };

  const fecharModalSucessoCadastro = () => {
    setModalSucessoCadastroAberto(false);
    window.location.reload();
  }

  return (
    <>
      <form id="formRestricoesHorarios" className="formRestricoesHorarios" onSubmit={registrar}>
        <div className="tabelaRestricoes">
          <div className="superiorRestricoes">
            <p className="col1">Ativo</p>
            <p className="col2">Início</p>
            <p className="col3">Fim</p>
            <p className="col5">Remover</p>
          </div>
          {restricoesHorarios.map((restricao, index) => (
            <div className="linhasRestricoes" key={restricao.id ? restricao.id : `novo-${index}`}>
              <input
                className="col1"
                type="checkbox"
                checked={restricao.ativo}
                onChange={(e) =>
                  alterarRestricao(index, "ativo", e.target.checked)
                }
              />
              <div className="col2">
                <input
                  type="datetime-local"
                  value={restricao.inicio}
                  onChange={(e) =>
                    alterarRestricao(index, "inicio", e.target.value)
                  }
                />
              </div>
              <div className="col3">
                <input
                  type="datetime-local"
                  value={restricao.fim}
                  onChange={(e) =>
                    alterarRestricao(index, "fim", e.target.value)
                  }
                />
              </div>
              <div className="col5">
                <button type="button" className="btnRemover" onClick={() => removerRestricao(index)}>
                  <FontAwesomeIcon icon={faMinus} />
                </button>
              </div>
            </div>
          ))}
        </div>
        <button className="btnAdicionarMais" type="button" onClick={adicionarNovaRestricao}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </form>
      <ModalSucesso
        isOpen={modalSucessoCadastroAberto}
        titulo="Sucesso!"
        mensagem={`Restricao de horário salva com sucesso`}
        acaoConfirmar={fecharModalSucessoCadastro}
      />
    </>
  );
};

export default HorarioBarbeiro;
