import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import ModalSucesso from '../modais/ModalSucesso';
const RegistroUsuarioForm = ({ textoBotao, idUsuario }) => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [funcao, setFuncao] = useState('');
  const [errors, setErrors] = useState({});
  const [modalSucessoCadastroAberto, setModalSucessoCadastroAberto] = useState(false);


  useEffect(() => {
    if (idUsuario) {
      const fetchUserData = async () => {
        try {
          const response = await api.post(`/usuario/id`, {
            idUsuario
          });
          const userData = response.data;
          setEmail(userData.email || '');
          setFuncao(userData.funcao || 'Cliente');
        } catch (error) {
          console.error('Erro ao carregar dados do usuário:', error);
        }
      };
      fetchUserData();
    }
  }, [idUsuario]);

  const registrar = async (e) => {
    e.preventDefault();
    if (validarFormulario()) {
      if (idUsuario) {
        await api.put(`/usuarios/atualizar/`, {
          idUsuario,
          senha: password,
          email,
          funcao,
        });
      } else {
        await api.post('/usuarios/criar/', {
          senha: password,
          email,
          funcao,
        });
      }
      setModalSucessoCadastroAberto(true);
      setTimeout(() => {
        fecharModalSucessoCadastro();
      }, 1500);
    } else {
      console.error('Erro ao registrar/atualizar usuário:');
    }
  };

  const validarFormulario = () => {
    const newErrors = {};

    const emailPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    if (!emailPattern.test(email)) {
      newErrors.email = 'Por favor, insira um e-mail válido.';
    }

    const passwordPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/;
    if (!passwordPattern.test(password)) {
      newErrors.password = 'A senha deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial.';
    }

    if (!funcao) {
      newErrors.funcao = 'Por favor, selecione uma função.';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const fecharModalSucessoCadastro = () => {
    setModalSucessoCadastroAberto(false);
    window.location.reload();
  }
  return (
    <>
      <form id='form-registro-usuario' onSubmit={registrar}>
        <div>
          <label>Email *</label>
          <input
            type="email"
            title="ex: seuemail@seudominio.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
        </div>
        <div>
          <label>Senha *</label>
          <input
            type="password"
            title="A senha deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma minúscula, um número e um caractere especial (@$!%*?&)"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
        </div>
        <div>
          <label>Função *</label>
          <select required value={funcao} onChange={(e) => setFuncao(e.target.value)}>
            <option value="" disabled hidden>
              Selecione uma função
            </option>
            <option value="Administrador">Administrador</option>
            <option value="Barbeiro">Barbeiro</option>
            <option value="Cliente">Cliente</option>
          </select>
        </div>
      </form>
      <ModalSucesso
        isOpen={modalSucessoCadastroAberto}
        mensagem={`Usuário ${email} ${idUsuario ? 'atualizado' : 'cadastrado'} com sucesso`}
      />
    </>

  );
};

export default RegistroUsuarioForm;
