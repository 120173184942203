import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';

const GoogleLoginButton = () => {
    const redirecionar = useNavigate();

    const sucessoLogin = async (response) => {
        const { credential } = response;
        try {
            const res = await api.post('/usuarios/google', { tokenId: credential });
            localStorage.setItem('token', res.data.token);
            const dadosParaEncriptar = res.data.userData
            const dadosEncriptados = await api.post('/encriptar', { dadosParaEncriptar });
            console.log(dadosEncriptados.data);
            localStorage.setItem('usuarioAtual', dadosEncriptados.data);
            redirecionar('/minha-conta');
        } catch (error) {
            console.error('Erro ao fazer login:', error);
        }
    };
    const erroLogin = (response) => {
        console.error('Login Falhou:', response);
    };
    return (
        <GoogleOAuthProvider clientId="215121742500-3vncm248d4ajn2m3k8vp3gfn5blkcg7j.apps.googleusercontent.com">
            <GoogleLogin
                onSuccess={sucessoLogin}
                onFailure={erroLogin}
            />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButton;
