import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import ModalSucesso from '../modais/ModalSucesso';

const FormRegistrarServico = ({ servicoId }) => {
  const [nome, setNome] = useState('');
  const [preco, setPreco] = useState('');
  const [numJanelas, setNumJanelas] = useState('');
  const [descricao, setDescricao] = useState('');
  const [tempoJanela, setTempoJanela] = useState('');
  const [errors, setErrors] = useState({});
  const [modalSucessoCadastroAberto, setModalSucessoCadastroAberto] = useState(false);

  useEffect(() => {
    if (servicoId) {
      const fetchUserData = async () => {
        try {
          const response = await api.post(`/servico/id`, {
            servicoId
          });
          const servicoData = response.data.servicoRetorno;
          const precoAjustado = servicoData.preco.replace('.', ',');
          setNome(servicoData.nome || '');
          setPreco(precoAjustado || '');
          setNumJanelas(servicoData.numJanelas || '');
          setDescricao(servicoData.descricao || '');
        } catch (error) {
          console.error('Erro ao carregar dados do servico:', error);
        }
      };
      fetchUserData();
    }
  }, [servicoId]);

  useEffect(() => {
    const fetchTempoJanela = async () => {
      try {
        const response = await api.get("/tempoJanela");
        setTempoJanela(response.data.tempoJanela);
      } catch (error) {
        console.error("Erro ao carregar o tempoJanela:", error);
      }
    };
    fetchTempoJanela();
  }, []);

  const registrar = async (e) => {
    e.preventDefault();
    if (validarFormulario()) {
      if (servicoId) {
        await api.put('/atualizarServico', {
          servicoId,
          nome: nome,
          preco: preco,
          numJanelas: numJanelas,
          descricao: descricao
        })
      } else {
        await api.post('/cadastroServico', {
          nome: nome,
          preco: preco,
          numJanelas: numJanelas,
          descricao: descricao
        })
      }
      setModalSucessoCadastroAberto(true);
      setTimeout(() => {
        fecharModalSucessoCadastro();
      }, 2500);
    } else {
      console.error('Erro ao registrar servico');
    }
  };

  const validarFormulario = () => {
    const newErrors = {};

    const nomePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
    if (!nomePattern.test(nome)) {
      newErrors.nome = 'Por favor, insira um nome válido.';
    }

    const precoPattern = /^\d{1,3}(\.\d{3})*,\d{2}$/;
    if (!precoPattern.test(preco)) {
      newErrors.preco = 'Insira um valor válido. Ex: 42,94';
    }

    if (!numJanelas) {
      newErrors.numJanelas = 'Por favor, insira a quantidade de janelas.';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const fecharModalSucessoCadastro = () => {
    setModalSucessoCadastroAberto(false);
    window.location.reload();
  }

  return (
    <>
      <form id='form-registro-servico' onSubmit={registrar}>
        <div>
          <label>Nome *</label>
          <input
            type="text"
            value={nome}
            title='Insira o nome do serviço'
            onChange={(e) => setNome(e.target.value)}
          />
          {errors.nome && <p style={{ color: 'red' }}>{errors.nome}</p>}
        </div>
        <div>
          <label>Valor *</label>
          <input
            type="text"
            value={preco}
            title='Insira o valor do serviço em Reais. ex: 45,99'
            onChange={(e) => setPreco(e.target.value)}
          />
          {errors.preco && <p style={{ color: 'red' }}>{errors.preco}</p>}
        </div>
        <div>
          <label>Numero de Intervalos *</label>
          <input
            type="text"
            value={numJanelas}
            title={`Insira a duração deste serviço em intervalos. Sabendo que um intervalo tem ${tempoJanela} minutos`}
            onChange={(e) => setNumJanelas(e.target.value)}
          />
          {errors.numJanelas && <p style={{ color: 'red' }}>{errors.numJanelas}</p>}
        </div>
        <div>
          <label>Descrição</label>
          <input type="text" value={descricao} onChange={(e) => setDescricao(e.target.value)} />
        </div>
      </form>
      <ModalSucesso
        isOpen={modalSucessoCadastroAberto}
        mensagem={`Serviço ${nome} ${servicoId ? 'atualizado' : 'cadastrado'} com sucesso.`}
      />
    </>
  )
}

export default FormRegistrarServico;