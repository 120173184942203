import '../../assets/styles/Modal.css';
import FormServicosBarbeiro from "../forms/ServicoBarbeiroForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
const ModalServicoBarbeiro = ({ isOpen, titulo, textoBotao, servicoId, nomeServico, barbeiros, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className="modal-header">
                    <h2>{titulo} {nomeServico}</h2>
                    <button style={{ backgroundColor: '#ffffff00', border: 'none', fontSize: '30px' }} onClick={onCancel}><FontAwesomeIcon icon={faClose} /></button>
                </div>
                <div className="modal-body">
                    <FormServicosBarbeiro
                        textoBotao={textoBotao}
                        barbeiros={barbeiros}
                        servicoId={servicoId}
                        nomeServico={nomeServico}
                    />
                </div>
                <div className="modal-footer">
                    <button type="submit" form='form-edicao-servicosBarbeiro'>{textoBotao}</button>
                </div>
            </div>
        </div>

    );
};

export default ModalServicoBarbeiro;
