import { useEffect, useState } from "react";
import { getUsuarios, ordenarUsuarios, desativarUsuario, buscarUsuario } from "../services/controller/usuarioController";
import SideMenu from "../components/SideMenu";
import ModalCadastro from "../components/modais/ModalCadastroUsuario";
import ModalConfirmacaoAcao from '../components/modais/ModalConfirmacaoAcao';
import ModalSucesso from '../components/modais/ModalSucesso';
import '../assets/styles/padraoMinhaConta.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDesc, faTrash, faEdit, faArrowLeft, faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import BotaoFlutuante from "../components/botaoFlutuante";

const PageUsuario = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [error, setError] = useState(null);
    const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
    const [modalEdicaoAberto, setModalEdicaoAberto] = useState(false);
    const [usuarioSelecionadoId, setUsuarioSelecionadoId] = useState(null);
    const [modalConfirmacaoAberto, setModalConfirmacaoAberto] = useState(false);
    const [usuarioParaExcluir, setUsuarioParaExcluir] = useState(null);
    const [emailUsuarioParaExcluir, setEmailUsuarioParaExcluir] = useState('');
    const [modalSucessoExclusaoAberto, setModalSucessoExclusaoAberto] = useState(false);
    const [qtdeExibicao, setQtdeExibicao] = useState(10);
    const [pagina, setPagina] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);

    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const userData = await getUsuarios(qtdeExibicao, pagina);
                setUsuarios(userData.usuarios);
                setTotalPaginas(userData.totalPaginas);
            } catch (error) {
                setError(error.message);
            }
        };
        fetchUsuarios();
    }, [pagina, qtdeExibicao]);

    const filtroBusca = async (e) => {
        const busca = e.target.value;
        const usuariosRetorno = await buscarUsuario(busca);
        setUsuarios(usuariosRetorno);
    };

    const ordenacao = (ordem, criterio) => {
        const usuariosOrdenados = ordenarUsuarios(usuarios, criterio, ordem);
        setUsuarios(usuariosOrdenados);
    };

    const fecharModalCadastro = () => {
        setModalCadastroAberto(false);
        setUsuarioSelecionadoId(null);
    };
    const fecharModalEdicao = () => {
        setModalEdicaoAberto(false);
        setUsuarioSelecionadoId(null);
    };
    const fecharModalConfirmacao = () => {
        setModalConfirmacaoAberto(false);
    };
    const fecharModalSucessoExclusao = () => {
        setModalSucessoExclusaoAberto(false);
        window.location.reload();
    }

    const confirmarExclusao = async () => {
        if (usuarioParaExcluir) {
            try {
                const userData = await desativarUsuario(usuarioParaExcluir, setError, pagina, qtdeExibicao);
                console.log(userData.usuarios)
                setUsuarios(userData.usuarios);
                setTotalPaginas(userData.totalPaginas);
                fecharModalConfirmacao();
                setModalSucessoExclusaoAberto(true);
            } catch (error) {
                setError(error.message);
                fecharModalConfirmacao();
            }
        }
    };

    const mudarPagina = (novaPagina) => {
        if (novaPagina > 0 && novaPagina <= totalPaginas) {
            setPagina(novaPagina);
        }
    };

    if (error) {
        return <p>Erro: {error}</p>;
    }

    return (
        <>
            <SideMenu /><BotaoFlutuante />
            <div className="divPrincipalMinhaConta">
                <ModalSucesso
                    isOpen={modalSucessoExclusaoAberto}
                    titulo={`Sucesso!`}
                    mensagem={`Usuario ${emailUsuarioParaExcluir} excluido com sucesso`}
                    acaoConfirmar={fecharModalSucessoExclusao}
                />
                <ModalConfirmacaoAcao
                    isOpen={modalConfirmacaoAberto}
                    titulo={`Excluir ${emailUsuarioParaExcluir}?`}
                    mensagem="Você tem certeza que deseja excluir este usuário?"
                    acaoCancelar={fecharModalConfirmacao}
                    acaoConfirmar={confirmarExclusao}
                />
                <ModalCadastro
                    isOpen={modalCadastroAberto}
                    titulo="Novo Usuário"
                    textoBotao={'Adicionar Usuário'}
                    onCancel={fecharModalCadastro}
                    modalSucesso={setModalCadastroAberto}
                />
                <ModalCadastro
                    isOpen={modalEdicaoAberto}
                    titulo="Alterar Usuário"
                    textoBotao={'Salvar edição'}
                    usuarioId={usuarioSelecionadoId}
                    onCancel={fecharModalEdicao}
                />
                <div className="superiorMinhaConta">
                    <h1>Usuários</h1>
                    <button onClick={() => setModalCadastroAberto(true)}>Adicionar Usuário</button>
                    <button className="btnAdicionarMobile" onClick={() => setModalCadastroAberto(true)}><FontAwesomeIcon icon={faPlus} /></button>
                </div>
                <div className="filtrosMinhaConta">
                    <input placeholder="buscar email" onChange={filtroBusca}></input>
                    <div className="ctrlExibicaoGrid">
                        <select value={qtdeExibicao} onChange={(e) => {
                            setQtdeExibicao(e.target.value)
                            setPagina(1)
                        }}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                        <div className="paginacaoMinhaConta">
                            <button className="btnPaginacao" onClick={() => mudarPagina(pagina - 1)} disabled={pagina === 1}><FontAwesomeIcon icon={faArrowLeft} /></button>
                            <button className="btnPaginacao" onClick={() => mudarPagina(pagina + 1)} disabled={pagina === totalPaginas}><FontAwesomeIcon icon={faArrowRight} /></button>
                        </div>
                    </div>
                </div>
                <div className="gridMinhaConta">
                    <div className="cabecalhoGridMinhaConta">
                        <div className="celulaComOrdenacao email">
                            <p>{'Email'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'email')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'email')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        <div className="celulaComOrdenacao coluna2">
                            <p>{'Função'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'funcao')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'funcao')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        <p className="coluna3 acoes" >{'Ações'}</p>
                    </div>
                    <div className="conteudoGridMinhaConta">
                        {usuarios.map((usuario) => (
                            <div className="linhasGridMinhaConta" key={usuario.id}>
                                <p className="celulaComOrdenacao email">{usuario.email}</p>
                                <p className="coluna2">{usuario.funcao}</p>
                                <div className="botoesAcao">
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={() => {
                                            setModalConfirmacaoAberto(true)
                                            setUsuarioParaExcluir(usuario.id)
                                            setEmailUsuarioParaExcluir(usuario.email)
                                        }}
                                        style={{ backgroundColor: 'var(--vermelhoLixeira)' }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        onClick={() => {
                                            setModalEdicaoAberto(true)
                                            setUsuarioSelecionadoId(usuario.id)
                                        }}
                                        style={{ backgroundColor: 'var(--azulEdicao)' }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageUsuario;
