import '../../assets/styles/Modal.css';
import FormBarbeiroServicos from "../forms/BarbeiroServicosForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const ModalBarbeiroServicos = ({ isOpen, titulo, textoBotao, servicos, barbeiroId, nomeBarbeiro, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className="modal-header">
                    <h2>{titulo} {nomeBarbeiro}</h2>
                    <button style={{ backgroundColor: '#ffffff00', border: 'none', fontSize: '30px' }} onClick={onCancel}><FontAwesomeIcon icon={faClose} /></button>
                </div>
                <div className="modal-body">
                    <FormBarbeiroServicos
                        textoBotao={textoBotao}
                        servicos={servicos}
                        barbeiroId={barbeiroId}
                        nomeBarbeiro={nomeBarbeiro}
                    />
                </div>
                <div className="modal-footer">
                    <button type="submit" form='form-registro-barbeiroServico'>{textoBotao}</button>
                </div>
            </div>
        </div>

    );
};

export default ModalBarbeiroServicos;
