import '../../assets/styles/Modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { getInfosAgendamento } from '../../services/controller/agendamentoController';
import { getTimeZone } from '../../services/controller/definicoesGeraisController';

const ModalInfosAgendamentos = ({ isOpen, agendamentoId, onCancel, setModalEdicaoAberto }) => {

  const [error, setError] = useState(null);
  const [agendamento, setAgendamento] = useState({});
  const [barbeiro, setBarbeiro] = useState({});
  const [cliente, setCliente] = useState({});
  const [servicos, setServicos] = useState([]);
  const [timezone, setTimezone] = useState('');
  const moment = require('moment-timezone');

  const statusMap = {
    1: 'Aguardando aprovação',
    2: 'Confirmado',
    3: 'Cancelado',
    4: 'Negado',
    5: 'Em andamento',
    6: 'Concluído',
  };

  useEffect(() => {
    const fetchServicos = async () => {
      try {
        const infos = await getInfosAgendamento(agendamentoId);
        setAgendamento(infos.agendamento[0]);
        setBarbeiro(infos.barbeiro[0]);
        setCliente(infos.cliente[0]);
        setServicos(infos.servicos);
        const timezone = await getTimeZone();
        setTimezone(timezone.timezone);
      } catch (error) {
        setError(error.message);
      }
    };

    if (agendamentoId) {
      fetchServicos();
    }
  }, [agendamentoId]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className="modal-header">
          <h2>Agendamento {agendamentoId}</h2>
          <button style={{ backgroundColor: '#ffffff00', border: 'none', fontSize: '30px' }} onClick={onCancel}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        <div className="modal-body">
          {error && <p>{error}</p>}
          <form className='infosBarbeiros'>
            <div className='inputTextReadOnly'>
              <label>Cliente</label>
              <input type="text" value={cliente.nome || ''} readOnly />
            </div>
            <div className='inputTextReadOnly'>
              <label>Barbeiro</label>
              <input type="text" value={barbeiro.nome || ''} readOnly />
            </div>
            <div className='inputTextReadOnly'>
              <label>Data</label>
              <input type="text" value={new Date(agendamento.dataAgendada).toLocaleDateString('pt-BR') || ''} readOnly />
            </div>
            <div className='inputTextReadOnly'>
              <label>Hora</label>
              <input type="text" value={moment.utc(agendamento.dataAgendada).utcOffset(timezone).format('HH:mm')|| ''} readOnly />
            </div>
            <div style={{ width: '100%' }}>
              <label>Serviços</label>
              <div className="servicos-container">
                {servicos.map((servico) => (
                  <div
                    key={servico.id}
                    className="servico-card"
                    style={{ opacity: 1 }}
                  >
                    <p>{servico.nome}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className='inputTextReadOnly'>
              <label>Status</label>
              <input type="text" value={statusMap[agendamento.status]} readOnly />
            </div>
          </form>
          <div className='botoesSuperior'>
            <button type='button' className='botaoEditarAgendamento' onClick={() => setModalEdicaoAberto(true)}>Editar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalInfosAgendamentos;
