import React from 'react';
import '../../assets/styles/Modal.css';

const ModalConfirmacao = ({ isOpen, titulo, mensagem, acaoCancelar, acaoConfirmar}) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-header">
                    <h2>{titulo}</h2>
                </div>
                <div className="modal-body">
                    <p>{mensagem}</p>
                </div>
                <div className="modal-footer">
                    <button className='btnCancelar' onClick={acaoCancelar}>Não</button>
                    <button className='btnConfirmar' onClick={acaoConfirmar}>Sim</button>
                </div>
            </div>
        </div>
    );
};

export default ModalConfirmacao;
