import '../../assets/styles/Modal.css';
import FormRegistrarBarbeiro from "../forms/CadastroBarbeiroForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const ModalCadastroBarbeiro = ({ isOpen, titulo, textoBotao, barbeiroId, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className="modal-header">
                    <h2>{titulo}</h2>
                    <button style={{ backgroundColor: '#ffffff00', border: 'none', fontSize: '30px' }} onClick={onCancel}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>
                <div className="modal-body">
                    <FormRegistrarBarbeiro
                        textoBotao={textoBotao}
                        barbeiroId={barbeiroId}
                    />
                </div>
                <div className="modal-footer">
                    <button type="submit" form='form-registro-barbeiro'>{textoBotao}</button>
                </div>
            </div>
        </div>
    );
};

export default ModalCadastroBarbeiro;
