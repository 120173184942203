import { useEffect, useState } from 'react';
import Calendar from '../components/Calendario';
import SideMenu from '../components/SideMenu';
import '../assets/styles/paginaAgendamentos.css';
import { getBarbeirosAtivos } from '../services/controller/barbeiroController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { getAgendamentos, obterTempoDoServico } from '../services/controller/agendamentoController';
import ModalCadastroAgendamento from "../components/modais/ModalCadastroAgendamento";
import ModalEdicaoBarbeiro from '../components/modais/ModalEdicaoAgendamento';
import ModalAgendamentosPendentes from '../components/modais/ModalAgendamentosPendentes';
import ModalTodosAgendamentos from '../components/modais/ModalTodosAgendamentos';
import ModalInfosAgendamentos from '../components/modais/ModalInfosAgendamentos';
import BotaoFlutuante from '../components/botaoFlutuante';
import ModalFiltrosEBotoes from '../components/modais/ModalFiltroAgendamentos';
import { getClientes } from '../services/controller/clienteController';
import { getTimeZone } from '../services/controller/definicoesGeraisController';

const Agendamentos = () => {
  const [barbeiros, setBarbeiros] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [agendamentos, setAgendamentos] = useState([]);
  const [error, setError] = useState(null);
  const [barbeirosSelecionados, setBarbeirosSelecionados] = useState([]);
  const [diaSelecionado, setDiaSelecionado] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [largurasAgendamentos, setLargurasAgendamentos] = useState({});
  const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
  const [modalEdicaoAberto, setModalEdicaoAberto] = useState(false);
  const [modalAgendamentosPendentesAberto, setModalAgendamentosPendentesAberto] = useState(false);
  const [modalTodosAgendamentos, setModalTodosAgendamentos] = useState(false);
  const [modalInfosAberto, setModalInfosAberto] = useState(false);
  const [modalFiltrosEBotoes, setModalFiltrosEBotoes] = useState(false);
  const [agendamentoSelecionadoId, setAgendamentoSelecionadoId] = useState(null);
  const [timezone, setTimezone] = useState('');
  const moment = require('moment-timezone');

  const coresFixas = ['#083D77', '#1098f7', '#48a9a6', '#0c6bb7', '#2ca1cf'];
  useEffect(() => {
    const fetchBarbeiros = async () => {
      try {
        const barbeiroData = await getBarbeirosAtivos();
        setBarbeiros(barbeiroData);
        setBarbeirosSelecionados(barbeiroData.map(barbeiro => barbeiro.id));
      } catch (error) {
        setError(error.message);
      }
    };
    const fetchAgendamentos = async () => {
      try {
        const agendamentosData = await getAgendamentos();
        setAgendamentos(agendamentosData);
        await calcularLargurasAgendamentos(agendamentosData);
      } catch (error) {
        setError(error.message);
      }
    };
    const fetchClientes = async () => {
      try {
        const clientesData = await getClientes();
        setClientes(clientesData);
      } catch (error) {
        setError(error.message);
      }
    };
    const fetchTimezone = async () => {
      try {
        const timezone = await getTimeZone();
        setTimezone(timezone.timezone);
      } catch (error) {
        setError(error.message);
      }
    };
    const calcularLargurasAgendamentos = async (agendamentosData) => {
      const larguras = {};
      const minutosGrid = 16 * 60;

      const promises = agendamentosData.map(async (agendamento) => {
        const tempoAgendamento = await obterTempoDoServico(agendamento.id);
        const porcentagem = (tempoAgendamento / minutosGrid) * 100;
        larguras[agendamento.id] = porcentagem;
      });
      await Promise.all(promises);
      setLargurasAgendamentos(larguras);
    };
    const fetchBarbeirosEAgendamentos = async () => {
      await Promise.all([fetchBarbeiros(), fetchAgendamentos(), fetchClientes(), fetchTimezone()]);
    };

    fetchBarbeirosEAgendamentos();
    const intervalId = setInterval(fetchAgendamentos, 5000);
    return () => clearInterval(intervalId);
  }, []);


  const fecharModalCadastro = () => {
    setModalCadastroAberto(false);
    setAgendamentoSelecionadoId(null);
  };
  const fecharModalEdicao = () => {
    setModalEdicaoAberto(false);
  };

  const fecharModalPendentes = () => {
    setModalAgendamentosPendentesAberto(false);
    setAgendamentoSelecionadoId(null);
  };

  const fecharModalTodos = () => {
    setModalTodosAgendamentos(false);
    setAgendamentoSelecionadoId(null);
  };

  const fecharModalInfos = () => {
    setModalInfosAberto(false);
    setAgendamentoSelecionadoId(null);
  };
  const fecharModalFiltrosEBotoes = () => {
    setModalFiltrosEBotoes(false);
  };

  const barbeirosSelecao = (barbeiroId) => {
    setBarbeirosSelecionados((prevSelecionados) =>
      prevSelecionados.includes(barbeiroId)
        ? prevSelecionados.filter((id) => id !== barbeiroId)
        : [...prevSelecionados, barbeiroId]
    );
  };

  const getCorBarbeiro = (barbeiroId) => {
    const index = barbeiros.findIndex(barbeiro => barbeiro.id === barbeiroId);
    return coresFixas[index % coresFixas.length];
  };

  if (error) {
    return <p>Erro: {error}</p>;
  }

  const formatarData = (data) => {
    return new Date(data).toISOString().split('T')[0];
  };

  const calcularLeftPorHora = (hora) => {
    const minutosGrid = 16 * 60;
    const [horas, minutos] = hora.split(':').map(Number);
    const horasReferentesAoInicioTL = horas - 6;
    const minutosPassados = (horasReferentesAoInicioTL * 60) + minutos;
    const porcentagem = (minutosPassados / minutosGrid) * 100;
    return porcentagem;
  };

  const agendamentosFiltrados = agendamentos
    .filter(agendamento =>
      barbeirosSelecionados.includes(agendamento.barbeiro_id) &&
      formatarData(agendamento.dataAgendada) === formatarData(diaSelecionado)
    );

  return (
    <>
      <BotaoFlutuante />
      <ModalFiltrosEBotoes
        isOpen={modalFiltrosEBotoes}
        onCancel={fecharModalFiltrosEBotoes}
        titulo={'Filtros'}
        barbeiros={barbeiros}
        barbeirosSelecionados={barbeirosSelecionados}
        setBarbeirosSelecionados={setBarbeirosSelecionados}
        setDiaSelecionado={setDiaSelecionado}
        onTodosClick={() => setModalTodosAgendamentos(true)}
        onPendentesClick={() => setModalAgendamentosPendentesAberto(true)}
        onNovoClick={() => setModalCadastroAberto(true)}
      />
      <ModalInfosAgendamentos
        isOpen={modalInfosAberto}
        agendamentoId={agendamentoSelecionadoId}
        onCancel={fecharModalInfos}
        setModalEdicaoAberto={setModalEdicaoAberto}
      />
      <ModalTodosAgendamentos
        isOpen={modalTodosAgendamentos}
        titulo={'Agendamentos'}
        onCancel={fecharModalTodos}
      />
      <ModalAgendamentosPendentes
        isOpen={modalAgendamentosPendentesAberto}
        titulo={'Agendamentos Pendentes'}
        onCancel={fecharModalPendentes}
      />
      <ModalCadastroAgendamento
        isOpen={modalCadastroAberto}
        titulo={'Novo Agendamento'}
        textoBotao={'Adicionar Agendamento'}
        clientes={clientes}
        barbeiros={barbeiros}
        diaSelecionado={formatarData(diaSelecionado)}
        onCancel={fecharModalCadastro}
      />
      <ModalEdicaoBarbeiro
        isOpen={modalEdicaoAberto}
        titulo={'Editar Agendamento'}
        textoBotao={'Salvar edição'}
        clientes={clientes}
        barbeiros={barbeiros}
        diaSelecionado={formatarData(diaSelecionado)}
        agendamentoId={agendamentoSelecionadoId}
        onCancel={fecharModalEdicao}
      />
      <SideMenu />
      <div className="divPrincipalMinhaConta">
        <div className="superiorMinhaConta">
          <h1>Agendamentos</h1>
          <div className='botoesSuperior'>
            <button onClick={() => setModalTodosAgendamentos(true)}>Todos</button>
            <button onClick={() => setModalAgendamentosPendentesAberto(true)}>Pendentes</button>
            <button onClick={() => setModalCadastroAberto(true)}>Novo</button>
            <button className='btnFiltro' onClick={() => setModalFiltrosEBotoes(true)}><FontAwesomeIcon icon={faFilter} /></button>
          </div>
        </div>
        <div className="gridAgendamentos">
          <div className='filtrosAgendamentos'>
            <Calendar onDiaSelecionadoChange={setDiaSelecionado} />
            <div className='linhaDivisao'></div>
            {barbeiros.map((barbeiro) => (
              <div
                className="barbeirosFiltro"
                key={barbeiro.id}
                onClick={() => barbeirosSelecao(barbeiro.id)}
                style={{
                  backgroundColor: barbeirosSelecionados.includes(barbeiro.id)
                    ? getCorBarbeiro(barbeiro.id)
                    : '#FFF',
                  color: barbeirosSelecionados.includes(barbeiro.id) ? '#fff' : '#000'
                }}
              >
                <div className='fotoENome'>
                  <div className='fotoBarbeiro' style={{ backgroundImage: `url('http://localhost:3333${barbeiro.imagem}')` }}></div>
                  <p className='nomeBarbeiro'>{barbeiro.nome}</p>
                </div>
                <div className='linhaDivisaoHorizontal'></div>
                {/* <div className='infosAgendamentosBarbeiros'>
                  <div className='statusAgendamentosBarbeiros'>
                    <p>Confirmados</p>
                    <p>20</p>
                  </div>
                  <div className='statusAgendamentosBarbeiros'>
                    <p>Finalizados</p>
                    <p>5</p>
                  </div>
                  <div className='statusAgendamentosBarbeiros'>
                    <p>Pendentes</p>
                    <p>5</p>
                  </div>
                </div> */}
              </div>
            ))}
          </div>
          <div className='visualizacaoHorarios'>
            <div className='visualizacaoDia'>
              {barbeiros.map((barbeiro) => {
                const agendamentosDoBarbeiro = agendamentosFiltrados.filter(
                  (agendamento) => agendamento.barbeiro_id === barbeiro.id && [2, 5, 6].includes(agendamento.status) && formatarData(agendamento.dataAgendada) === formatarData(diaSelecionado)
                );

                return (
                  <div key={barbeiro.id} className="linhaBarbeiro" style={{ display: (agendamentosDoBarbeiro?.length || 0) === 0 ? 'none' : 'flex' }}>
                    {agendamentosDoBarbeiro?.map((agendamento, index) => {
                      const dataObj = new Date(agendamento.dataAgendada);
                      //substituir o america/sao_paulo por -03:00 que virá do banco
                      const hora = moment.utc(dataObj).utcOffset(timezone).format('HH:mm');
                      // const hora = moment.utc(dataObj).tz('America/Sao_Paulo').format('HH:mm');
                      const porcentagemWidth = largurasAgendamentos[agendamento.id] || 0;
                      return (
                        <div key={agendamento.id}
                          style={{
                            display: 'flex',
                            position: 'relative',
                            left: `${calcularLeftPorHora(hora)}%`,
                            width: `${porcentagemWidth}%`,
                            top: `${index * -40}px`
                          }}
                          className='itemAgendamento'
                        >
                          <div
                            onClick={() => {
                              setModalInfosAberto(true);
                              setAgendamentoSelecionadoId(agendamento.id);
                            }}
                            className="agendamentoItem"
                            style={{
                              backgroundColor: getCorBarbeiro(agendamento.barbeiro_id),
                            }}
                          >
                            <p>{`${hora}`}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div className='linhaDoTempo'>
                <p>6:00</p>
                <p>8:00</p>
                <p>10:00</p>
                <p>12:00</p>
                <p>14:00</p>
                <p>16:00</p>
                <p>18:00</p>
                <p>20:00</p>
              </div>
            </div>

            <div className='visualizacaoSemana'>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Agendamentos;
