import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/criarConta.css';
import ModalSucesso from '../../src/components/modais/ModalSucesso';

const CriarConta = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [error, setError] = useState('');
  const [telefone, setTelefone] = useState('');
  const [proximo, setProximo] = useState(true);
  const [modalSucessoCadastroAberto, setModalSucessoCadastroAberto] = useState(false);
  const redirecionar = useNavigate();

  useEffect(() => {
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        setError('As senhas não coincidem.');
        setProximo(true);
      } else {
        if (email) {
          setError('');
          setProximo(false);
        }
      }
    } else {
      setProximo(true);
    }
  }, [email, password, confirmPassword]);

  const registrar = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/novoCliente', {
        nome: nome,
        email: email,
        senha: password,
        telefone: telefone,
      });
      if (response.status === 201) {
        setModalSucessoCadastroAberto(true);
      }
    } catch (error) {
      console.error('Erro ao registrar o cliente:', error);
    }
  };
  const fecharModalSucessoCadastro = () => {
    setModalSucessoCadastroAberto(false);
    redirecionar('/login');
  }


  return (
    <div className="formCriarConta">
      <div className="modal">
        <div className="modal-header" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
          <h2>Criar Conta</h2>
        </div>
        <div className="modal-body">
          <form id='criarConta' onSubmit={registrar}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <div>
                <label>Nome</label>
                <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
              </div>
              <div>
                <label>Telefone</label>
                <input type="text" value={telefone} onChange={(e) => setTelefone(e.target.value)} />
              </div>
              <div>
                <label>Email</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div>
                <label>Senha</label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div>
                <label>Confirme sua senha</label>
                <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </div>
            </div>
          </form>
          <ModalSucesso
            isOpen={modalSucessoCadastroAberto}
            titulo="Sucesso!"
            mensagem={`Conta criada com sucesso, faça o login e aproveite todos os beneficios de usar a plataforma Cortae`}
            acaoConfirmar={fecharModalSucessoCadastro}
          />
        </div>
        <div className="modal-footer">
          <button type="submit" form='criarConta' disabled={proximo}>Criar Conta</button>
        </div>
      </div>
    </div>
  );
};

export default CriarConta;
