import '../../assets/styles/Modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { getAgendamentosBarbeirosEClientes } from '../../services/controller/agendamentoController';

// Definindo o mapeamento dos status
const statusEnum = {
  1: 'Aguardando aprovação',
  2: 'Confirmado',
  3: 'Cancelado',
  4: 'Negado',
  5: 'Em andamento',
  6: 'Concluído'
};

const ModalTodosAgendamentos = ({ isOpen, titulo, onCancel }) => {
  const [agendamentos, setAgendamentos] = useState([]);
  const [barbeiros, setBarbeiros] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBarbeirosAgendamentosClientes = async () => {
      try {
        const agendamentosData = await getAgendamentosBarbeirosEClientes();
        setAgendamentos(agendamentosData.todos);
        setBarbeiros(agendamentosData.barbeiros);
        setClientes(agendamentosData.clientes);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchBarbeirosAgendamentosClientes();
  }, []);

  if (!isOpen) return null;

  const buscaBarbeiro = (barbeiroId) => {
    const barbeiro = barbeiros.find(b => b.id === barbeiroId);
    return barbeiro ? barbeiro.nome : 'Barbeiro desconhecido';
  };

  const buscaCliente = (clienteId) => {
    const cliente = clientes.find(c => c.id === clienteId);
    return cliente ? cliente.nome : 'Cliente desconhecido';
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className="modal-header">
          <h2>{titulo}</h2>
          <button style={{ backgroundColor: '#ffffff00', border: 'none', fontSize: '30px' }} onClick={onCancel}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        <div className="modal-body">
          {error && <p>{error}</p>}
          <div className='listaAgendamentosModal'>
            {agendamentos.map((agendamento) => (
              <div key={agendamento.id} className='agendamentoPendenteItem'>
                <p>{new Date(agendamento.dataAgendada).toLocaleDateString('pt-BR')}</p>
                <p>{new Date(agendamento.dataAgendada).toLocaleTimeString('pt-BR')}</p>
                <p>{buscaBarbeiro(agendamento.barbeiro_id)}</p>
                <p>{buscaCliente(agendamento.cliente_id)}</p>
                <p>{statusEnum[agendamento.status]}</p>
                {/* <div className="botoesAcao">
                  <FontAwesomeIcon
                    icon={faCheck}
                    onClick={() => aprovarAgendamento(agendamento.id, 2, setAgendamentosPendentes)}
                    style={{ backgroundColor: 'var(--pendentes)' }}
                  />
                  <FontAwesomeIcon
                    icon={faX}
                    onClick={() => aprovarAgendamento(agendamento.id, 4, setAgendamentosPendentes)}
                    style={{ backgroundColor: 'var(--vermelhoLixeira)' }}
                  />
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalTodosAgendamentos;