import React from 'react';
import '../../assets/styles/Modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const ModalConfirmacao = ({ isOpen, mensagem}) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay-sucesso">
            <div className="modal-sucesso">
                <div className="modal-body">
                    <p>{mensagem} <FontAwesomeIcon icon={faCheckCircle} color='green'/></p>
                </div>
            </div>
        </div>
    );
};

export default ModalConfirmacao;
