import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import CriarConta from './pages/CriarContaPage';
import MinhaConta from './pages/PageMinhaConta';
import Usuarios from './pages/PageUsuarios';
import Barbeiros from './pages/PageBarbeiros';
import Agendamentos from './pages/PageAgendamento';
import Clientes from './pages/PageCliente';
import Servicos from './pages/PageServicos';
import Dashboard from './pages/PageDashboard';
import AgendamentosCliente from './pages/PageAgendamentoClientes';
import './assets/styles/gerais.css';
import { RotaAdmin, RotaBarbeiroAdmin, RotaPublica, RotaCliente } from './utils/auth';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<RotaPublica><LoginPage /></RotaPublica>} />
          <Route path="criar-conta" element={<RotaPublica><CriarConta /></RotaPublica>} />
          <Route path="dashboard" element={<RotaBarbeiroAdmin><Dashboard /></RotaBarbeiroAdmin>} />
          <Route path="minha-conta" element={<RotaCliente><MinhaConta /></RotaCliente>} />
          <Route path="minha-conta/meus-agendamentos" element={<RotaCliente><AgendamentosCliente /></RotaCliente>} />
          <Route path="gerenciar/usuarios" element={<RotaAdmin><Usuarios /></RotaAdmin>} />
          <Route path="gerenciar/servicos" element={<RotaAdmin><Servicos /></RotaAdmin>} />
          <Route path="gerenciar/barbeiros" element={<RotaAdmin><Barbeiros /></RotaAdmin>} />
          <Route path="gerenciar/clientes" element={<RotaAdmin><Clientes /></RotaAdmin>} />
          <Route path="gerenciar/horarios" element={<RotaBarbeiroAdmin><Barbeiros /></RotaBarbeiroAdmin>} />
          <Route path="gerenciar/agendamentos" element={<RotaBarbeiroAdmin><Agendamentos /></RotaBarbeiroAdmin>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
