import React from 'react';
import SideMenu from "../components/SideMenu";
import '../assets/styles/minhaConta.css';
import BotaoFlutuante from '../components/botaoFlutuante';

const MinhaConta = () => {

    return (
        <>
            <SideMenu /><BotaoFlutuante />
            <div className="divPrincipalMinhaConta">
                <p style={{ color: "#000" }}>Essa é a página de minha conta para o Cliente</p>
            </div>
        </>
    );
};

export default MinhaConta;
