import '../assets/styles/homePage.css'
import { isLoggedIn } from '../utils/auth';
import logoCasaBrasil from '../assets/images/CasaBrasilLogo.png';

const HomePage = () => {
    return (
        <>
            <div className='headerHome'>
                <img src={logoCasaBrasil} alt="Logo" />
            </div>
            <div className="bannerPrincipalHome">

                <h1>Seu estilo, nossa história!</h1>
                <div className='btnHome'>
                    {/* <a className='btnServicosHome' href='#servicos'>Nossos serviços</a> */}
                    <a className='btnServicosHome' href={isLoggedIn ? '/minha-conta/meus-agendamentos' : '/login'}>Agendar horário</a>
                </div>

            </div>
            {/* <div id='servicos' className="bannerServicos">
                <h1>Nossos Serviços</h1>
            </div> */}

        </>

    );
}
export default HomePage;


