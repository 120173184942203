import { useEffect, useState } from "react";
import SideMenu from "../components/SideMenu";
import ModalCadastroServico from "../components/modais/ModalCadastroServico";
import ModalConfirmacaoAcao from '../components/modais/ModalConfirmacaoAcao';
import ModalSucesso from '../components/modais/ModalSucesso';
import ModalServicoBarbeiro from '../components/modais/ModalServicoBarbeiro';
import '../assets/styles/padraoMinhaConta.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDesc, faTrash, faEdit, faUser, faPlus } from '@fortawesome/free-solid-svg-icons';
import { buscarServico, desativarServico, getServicos, ordenarServicos } from "../services/controller/servicoController";
import { getBarbeirosAtivos } from "../services/controller/barbeiroController";
import BotaoFlutuante from "../components/botaoFlutuante";

const PageServicos = () => {
    const [servicos, setServicos] = useState([]);
    const [barbeiros, setBarbeiros] = useState([]);
    const [error, setError] = useState(null);
    const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
    const [modalEdicaoAberto, setModalEdicaoAberto] = useState(false);
    const [servicoSelecionadoId, setServicoSelecionadoId] = useState(null);
    const [modalConfirmacaoAberto, setModalConfirmacaoAberto] = useState(false);
    const [servicoParaExcluir, setServicoParaExcluir] = useState(null);
    const [nomeServico, setNomeServico] = useState('');
    const [modalSucessoExclusaoAberto, setModalSucessoExclusaoAberto] = useState(false);
    const [modalServicoBarbeiroAberto, setModalServicoBarbeiroAberto] = useState(false);

    useEffect(() => {
        const fetchServicos = async () => {
            try {
                const servicosData = await getServicos();
                setServicos(servicosData);
                const barbeiroData = await getBarbeirosAtivos();
                setBarbeiros(barbeiroData);
            } catch (error) {
                setError(error.message);
            }
        };
        fetchServicos();
    }, []);

    const filtroBusca = async (e) => {
        const busca = e.target.value;
        const servicosRetorno = await buscarServico(busca);
        setServicos(servicosRetorno);
    };

    const ordenacao = (ordem, criterio) => {
        const servicosOrdenados = ordenarServicos(servicos, criterio, ordem);
        setServicos(servicosOrdenados);
    };

    const fecharModalCadastro = () => {
        setModalCadastroAberto(false);
        setServicoSelecionadoId(null);
    };
    const fecharModalEdicao = () => {
        setModalEdicaoAberto(false);
        setServicoSelecionadoId(null);
    };
    const fecharModalConfirmacao = () => {
        setModalConfirmacaoAberto(false);
    };

    const fecharModalServicoBarbeiro = () => {
        setModalServicoBarbeiroAberto(false);
    };

    const confirmarExclusao = async () => {
        if (servicoParaExcluir) {
            try {
                await desativarServico(servicoParaExcluir, setServicos, setError);
                fecharModalConfirmacao();
                setModalSucessoExclusaoAberto(true);
                setTimeout(() => {
                    setModalSucessoExclusaoAberto(false);
                }, 2000);
            } catch (error) {
                setError(error.message);
                fecharModalConfirmacao();
            }
        }
    };

    if (error) {
        return error;
    }

    return (
        <>
            <SideMenu /><BotaoFlutuante />
            <div className="divPrincipalMinhaConta">
                <ModalSucesso
                    isOpen={modalSucessoExclusaoAberto}
                    mensagem={`Serviço ${nomeServico} excluido com sucesso`}
                />
                <ModalServicoBarbeiro
                    isOpen={modalServicoBarbeiroAberto}
                    titulo={`Execução Serviço`}
                    servicoId={servicoSelecionadoId}
                    nomeServico={nomeServico}
                    barbeiros={barbeiros}
                    textoBotao={'Salvar Barbeiros Serviço'}
                    onCancel={fecharModalServicoBarbeiro}
                />
                <ModalConfirmacaoAcao
                    isOpen={modalConfirmacaoAberto}
                    titulo={`Excluir ${nomeServico}?`}
                    mensagem="Você tem certeza que deseja excluir este serviço?"
                    acaoCancelar={fecharModalConfirmacao}
                    acaoConfirmar={confirmarExclusao}
                />
                <ModalCadastroServico
                    isOpen={modalCadastroAberto}
                    titulo="Novo Serviço"
                    textoBotao={'Adicionar Serviço'}
                    onCancel={fecharModalCadastro}
                />
                <ModalCadastroServico
                    isOpen={modalEdicaoAberto}
                    titulo="Editar Serviço"
                    textoBotao={'Salvar Edição'}
                    servicoId={servicoSelecionadoId}
                    onCancel={fecharModalEdicao}
                />
                <div className="superiorMinhaConta">
                    <h1>Serviços</h1>
                    <button onClick={() => setModalCadastroAberto(true)}>Adicionar Serviços</button>
                    <button className="btnAdicionarMobile" onClick={() => setModalCadastroAberto(true)}><FontAwesomeIcon icon={faPlus} /></button>
                </div>
                <div className="filtrosMinhaConta">
                    <input placeholder="buscar serviço" onChange={filtroBusca}></input>
                </div>
                <div className="gridMinhaConta">
                    <div className="cabecalhoGridMinhaConta">
                        <div className="celulaComOrdenacao">
                            <p>{'Nome'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'nome')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'nome')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        <div className="celulaComOrdenacao">
                            <p>{'Valor'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'preco')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'preco')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        <div className="celulaComOrdenacao">
                            <p>{'Janelas'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'numJanelas')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'numJanelas')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        <p className="coluna3 acoes" >{'Ações'}</p>
                    </div>
                    <div className="conteudoGridMinhaConta">
                        {servicos.map((servico) => (
                            <div className="linhasGridMinhaConta" key={servico.id}>
                                <p className="celulaComOrdenacao">{servico.nome}</p>
                                <p className="celulaComOrdenacao">{servico.preco}</p>
                                <p className="celulaComOrdenacao">{servico.numJanelas}</p>
                                <div className="botoesAcao">
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ backgroundColor: 'var(--vermelhoLixeira)' }}
                                        onClick={() => {
                                            setModalConfirmacaoAberto(true)
                                            setServicoParaExcluir(servico.id)
                                            setNomeServico(servico.nome)
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        onClick={() => {
                                            setModalEdicaoAberto(true)
                                            setServicoSelecionadoId(servico.id)
                                        }}
                                        style={{ backgroundColor: 'var(--azulEdicao)' }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        onClick={() => {
                                            setNomeServico(servico.nome)
                                            setModalServicoBarbeiroAberto(true)
                                            setServicoSelecionadoId(servico.id)
                                        }}
                                        style={{ backgroundColor: 'var(--servicos)' }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageServicos;
