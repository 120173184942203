import '../../assets/styles/Modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { aprovarAgendamento, getAgendamentosPendentes } from '../../services/controller/agendamentoController';

const ModalAgendamentosPendentes = ({ isOpen, titulo, onCancel }) => {
  const [agendamentosPendentes, setAgendamentosPendentes] = useState([]);
  const [barbeiros, setBarbeiros] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBarbeirosAgendamentosClientes = async () => {
      try {
        const agendamentosData = await getAgendamentosPendentes();
        setAgendamentosPendentes(agendamentosData.pendentes);
        setBarbeiros(agendamentosData.barbeiros);
        setClientes(agendamentosData.clientes);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchBarbeirosAgendamentosClientes();
    const intervalId = setInterval(fetchBarbeirosAgendamentosClientes, 5000);
    return () => clearInterval(intervalId);
  }, []);

  if (!isOpen) return null;

  const buscaBarbeiro = (barbeiroId) => {
    const barbeiro = barbeiros.find(b => b.id === barbeiroId);
    return barbeiro ? barbeiro.nome : 'Barbeiro desconhecido';
  };

  const buscaCliente = (clienteId) => {
    const cliente = clientes.find(c => c.id === clienteId);
    return cliente ? cliente.nome : 'Cliente desconhecido';
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className="modal-header">
          <h2>{titulo}</h2>
          <button style={{ backgroundColor: '#ffffff00', border: 'none', fontSize: '30px' }} onClick={onCancel}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        <div className="modal-body">
          {error && <p>{error}</p>}
          <div className='listaAgendamentosModal'>
            {agendamentosPendentes.length === 0 ? (
              <p>Nenhum agendamento pendente</p>
            ) : (
              <>
                <div className="agendamentoPendenteItem">
                  <p>Data</p>
                  <p>Horário</p>
                  <p>Barbeiro</p>
                  <p>Cliente</p>
                  <p className='tituloAcoes'>Ações</p>
                </div>
                {agendamentosPendentes.map((agendamento) => (
                  <div key={agendamento.id} className="agendamentoPendenteItem">
                    <p>{new Date(agendamento.dataAgendada).toLocaleDateString('pt-BR')}</p>
                    <p>{new Date(agendamento.dataAgendada).toLocaleTimeString('pt-BR')}</p>
                    <p>{buscaBarbeiro(agendamento.barbeiro_id)}</p>
                    <p>{buscaCliente(agendamento.cliente_id)}</p>
                    <div className="botoesAcao">
                      <FontAwesomeIcon
                        icon={faCheck}
                        onClick={() => aprovarAgendamento(agendamento.id, 2, setAgendamentosPendentes)}
                        style={{ backgroundColor: 'var(--pendentes)' }}
                      />
                      <FontAwesomeIcon
                        icon={faCancel}
                        onClick={() => aprovarAgendamento(agendamento.id, 4, setAgendamentosPendentes)}
                        style={{ backgroundColor: 'var(--vermelhoLixeira)' }}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAgendamentosPendentes;
