import '../../assets/styles/Modal.css';
import FormRegistroAgendamento from "../forms/CadastroAgendamentoForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
const ModalCadastroAgendamento = ({ isOpen, titulo, textoBotao, clientes, barbeiros, diaSelecionado, agendamentoId, clienteSelecionado, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className="modal-header">
                    <h2>{titulo}</h2>
                    <button style={{ backgroundColor: '#ffffff00', border: 'none', fontSize: '30px' }} onClick={onCancel}><FontAwesomeIcon icon={faClose} /></button>
                </div>
                <div className="modal-body">
                    <FormRegistroAgendamento
                        textoBotao={textoBotao}
                        clientes={clientes}
                        barbeiros={barbeiros}
                        dataSelecaoFiltro={diaSelecionado}
                        agendamentoId={agendamentoId}
                        clienteAtual={clienteSelecionado}
                    />
                </div>
                <div className="modal-footer">
                    <button type="submit" form='form-registro-agendamento'>{clienteSelecionado ? 'Solicitar agendamento' : textoBotao}</button>
                </div>
            </div>
        </div>

    );
};

export default ModalCadastroAgendamento;
