import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import '../../assets/styles/registroAgendamento.css';
import ModalSucesso from '../modais/ModalSucesso';
import { getTimeZone } from '../../services/controller/definicoesGeraisController';


const RegistroAgendamento = ({ textoBotao, clientes, barbeiros, dataSelecaoFiltro, agendamentoId, clienteAtual }) => {
  const hoje = new Date().toISOString().split('T')[0];
  const [clienteSelecionado, setClienteSelecionado] = useState(clienteAtual ? `${clienteAtual}` : '');
  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [barbeiroSelecionado, setBarbeiroSelecionado] = useState(null);
  const [servicosBarbeiro, setServicosBarbeiro] = useState([]);
  const [diaSelecionado, setDiaSelecionado] = useState(dataSelecaoFiltro < hoje ? hoje : dataSelecaoFiltro);
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);
  const [horarioSelecionado, setHorarioSelecionado] = useState(null);
  const [dataAgendamento, setDataAgendamento] = useState('');
  const [modalSucessoCadastroAberto, setModalSucessoCadastroAberto] = useState(false);
  const [timezone, setTimezone] = useState('');

  useEffect(() => {
    const fetchHorariosDisponiveis = async () => {
      try {
        const horariosDisponiveis = await api.post(`/horariosDisponiveis`, {
          diaSelecionado, servicosSelecionados, barbeiroSelecionado
        });
        setHorariosDisponiveis(horariosDisponiveis.data);
        const timezone = await getTimeZone();
        setTimezone(timezone.timezone);
      } catch (error) {
        console.error('Erro ao carregar dados do agendamento:', error);
      }
    };
    fetchHorariosDisponiveis();
  }, [diaSelecionado, servicosSelecionados, barbeiroSelecionado]);
  const registrar = async (e) => {
    e.preventDefault();
    try {
      console.log(clienteSelecionado, barbeiroSelecionado, servicosSelecionados, dataAgendamento)
      await api.post('/cadastroAgendamento', {
        clienteSelecionado,
        barbeiroSelecionado,
        servicosSelecionados,
        dataAgendamento,
        status: clienteAtual ? 1 : 2
      });
      setModalSucessoCadastroAberto(true);
    } catch (error) {
      console.error('Erro ao registrar barbeiro:', error);
    }
  };

  const selecionarBarbeiro = async (barbeiroId) => {
    setBarbeiroSelecionado(barbeiroId);
    const servicosBarbeiroData = await api.post('/barbeiroServicos', {
      barbeiroId,
    });
    setServicosSelecionados([]);
    setHorarioSelecionado(null);
    setServicosBarbeiro(servicosBarbeiroData.data);
  };

  const selecionarServico = (servicoId) => {
    setServicosSelecionados((prevSelecionados) =>
      prevSelecionados.includes(servicoId)
        ? prevSelecionados.filter((id) => id !== servicoId)
        : [...prevSelecionados, servicoId]
    );
    setHorarioSelecionado(null);
  };

  const selecionarHorario = (horario) => {
    setHorarioSelecionado(horario);
    // substituir -03 por uma busca que descobre qual o timezone da barbearia em si.
    setDataAgendamento(`${diaSelecionado} ${horario}:00${timezone}`);
  };

  const fecharModalSucessoCadastro = () => {
    setModalSucessoCadastroAberto(false);
    window.location.reload();
  }

  return (
    <>
      <form id='form-registro-agendamento' onSubmit={registrar}>
        <div style={{ display: clienteAtual ? 'none' : 'block' }}>
          <label>Selecione o cliente</label>
          <select
            value={clienteSelecionado}
            onChange={(e) => setClienteSelecionado(e.target.value)}>
            <option value=''>Selecione...</option>
            {clientes.map((cliente) => (
              <option key={cliente.id} value={cliente.id}>
                {cliente.nome}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Selecione o barbeiro</label>
          <div className="barbeiros-container">
            {barbeiros.map((barbeiro) => (
              <div
                key={barbeiro.id}
                className={`barbeiro-card ${barbeiroSelecionado === barbeiro.id ? 'selecionado' : ''}`}
                onClick={() => selecionarBarbeiro(barbeiro.id)}
                style={{
                  opacity: barbeiroSelecionado === barbeiro.id || barbeiroSelecionado === null ? 0.3 : 0.2,
                }}
              >
                <div className="foto-circulo">
                  <FontAwesomeIcon icon={faUser} size="3x" />
                </div>
                <p>{barbeiro.nome}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <label>Selecione os serviços</label>
          <div className="servicos-container">
            {servicosBarbeiro.map((servico) => (
              <div
                key={servico.id}
                className={`servico-card ${servicosSelecionados.includes(servico.id) ? 'selecionado' : ''}`}
                onClick={() => selecionarServico(servico.id)}
                style={{
                  background: servicosSelecionados.includes(servico.id) ? '#e0e0e0' : '',
                }}
              >
                <input
                  type="checkbox"
                  checked={servicosSelecionados.includes(servico.id)}
                  onClick={(event) => event.stopPropagation()}
                  onChange={() => selecionarServico(servico.id)}
                  style={{ marginRight: '8px' }}
                />
                <p>{servico.nome}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <label>Selecione o dia</label>
          <input
            type="date"
            value={diaSelecionado}
            min={hoje}
            onChange={(e) => setDiaSelecionado(e.target.value)}
          />
        </div>
        <div>
          <label>Selecione o horário</label>
          <div className="horarios-container">
            {horariosDisponiveis.map((horario) => (
              <div
                key={horario}
                className={`horario-card ${horarioSelecionado === horario ? 'selecionado' : ''}`}
                onClick={() => selecionarHorario(horario)}
                style={{
                  opacity: horarioSelecionado === horario || horarioSelecionado === null ? 1 : 0.2,
                }}
              >
                <FontAwesomeIcon icon={faClock} />
                <p>{horario}</p>
              </div>
            ))}
          </div>
        </div>
      </form>
      <ModalSucesso
        isOpen={modalSucessoCadastroAberto}
        titulo="Sucesso!"
        mensagem={`Agendamento dia ${dataAgendamento} ${clienteAtual ? 'solicitado' : 'cadastrado'} com sucesso`}
        acaoConfirmar={fecharModalSucessoCadastro}
      />
    </>
  );
};

export default RegistroAgendamento;
