import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import '../../assets/styles/criarConta.css';
import ModalSucesso from '../modais/ModalSucesso';

const CadastroClienteFrom = ({ textoBotao, clienteId }) => {
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cep, setCep] = useState('');
  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState('');
  const [bairro, setBairro] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [errorCep, setErrorCep] = useState('');
  const [errors, setErrors] = useState({});
  const [modalSucessoCadastroAberto, setModalSucessoCadastroAberto] = useState(false);

  useEffect(() => {
    if (clienteId) {
      const fetchUserData = async () => {
        try {
          const response = await api.post(`/cliente/id`, {
            clienteId
          });
          const clienteData = response.data.cliente;
          const emailCliente = response.data.emailCliente;
          setEmail(emailCliente || '');
          setNome(clienteData.nome || '');
          setTelefone(clienteData.telefone || '');
          setCep(clienteData.cep || '');
          setEstado(clienteData.estado || '');
          setCidade(clienteData.cidade || '');
          setBairro(clienteData.bairro || '');
          setLogradouro(clienteData.logradouro || '');
          setNumero(clienteData.numero || '');
          setComplemento(clienteData.complemento || '');
        } catch (error) {
          console.error('Erro ao carregar dados do cliente:', error);
        }
      };
      fetchUserData();
    }
  }, [clienteId]);

  const registrar = async (e) => {
    e.preventDefault();
    if (validarFormulario()) {
      if (clienteId) {
        await api.put('/atualizarCliente', {
          clienteId,
          nome: nome,
          email: email,
          telefone: telefone,
          cep: cep,
          estado: estado,
          cidade: cidade,
          bairro: bairro,
          logradouro: logradouro,
          numero: numero,
          complemento: complemento
        });
      } else {
        await api.post('/cadastroCliente', {
          nome: nome,
          email: email,
          telefone: telefone,
          cep: cep,
          estado: estado,
          cidade: cidade,
          bairro: bairro,
          logradouro: logradouro,
          numero: numero,
          complemento: complemento
        });
      }
      setModalSucessoCadastroAberto(true);
      setTimeout(() => {
        fecharModalSucessoCadastro();
      }, 2000);
    } else {
      console.error('Erro ao registrar o cliente:');
    }
  };
  const checkCep = () => {
    const cepFormatado = cep.replace(/\D/g, '');
    if (cepFormatado.length === 8) {
      fetch(`https://viacep.com.br/ws/${cepFormatado}/json/`)
        .then(res => res.json())
        .then(data => {
          console.log(data)
          if (!data.erro) {
            setErrorCep('');
            setEstado(data.estado || '');
            setCidade(data.localidade || '');
            setBairro(data.bairro || '');
            setLogradouro(data.logradouro || '');
          } else {
            setErrorCep('CEP não encontrado.');
            setEstado('');
            setCidade('');
            setBairro('');
            setLogradouro('');
          }
        })
        .catch(() => {
          setErrorCep('Erro ao buscar o CEP.');
          setEstado('');
          setCidade('');
          setBairro('');
          setLogradouro('');
        });
    } else {
      setErrorCep('CEP inválido.');
    }
  };

  const validarFormulario = () => {
    const newErrors = {};

    const nomePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
    if (!nomePattern.test(nome)) {
      newErrors.nome = 'Por favor, insira um nome válido.';
    }

    const telefonePattern = /^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/;
    if (!telefonePattern.test(telefone)) {
      newErrors.telefone = 'Insira um telefone válido. Ex: (11) 91234-5678';
    }

    const emailPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    if (!emailPattern.test(email)) {
      newErrors.email = 'Por favor, insira um e-mail válido.';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const fecharModalSucessoCadastro = () => {
    setModalSucessoCadastroAberto(false);
    window.location.reload();
  }

  return (
    <>
      <form id='form-registro-cliente' onSubmit={registrar} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <div>
            <label>Nome *</label>
            <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
            {errors.nome && <p style={{ color: 'red' }}>{errors.nome}</p>}
          </div>
          <div>
            <label>Email *</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
          </div>
          <div>
            <label>Telefone *</label>
            <input type="text" value={telefone} onChange={(e) => setTelefone(e.target.value)} />
            {errors.telefone && <p style={{ color: 'red' }}>{errors.telefone}</p>}
          </div>
          <div>
            <label>Cep</label>
            <input type="text" value={cep} onChange={(e) => setCep(e.target.value)} onBlur={checkCep} />
            {errorCep && <p style={{ color: 'red' }}>{errorCep}</p>}
          </div>
          <div>
            <label>Estado</label>
            <input type="text" value={estado} onChange={(e) => setEstado(e.target.value)} readOnly />
          </div>
          <div>
            <label>Cidade</label>
            <input type="text" value={cidade} onChange={(e) => setCidade(e.target.value)} readOnly />
          </div>
          <div>
            <label>Bairro</label>
            <input type="text" value={bairro} onChange={(e) => setBairro(e.target.value)} readOnly />
          </div>
          <div>
            <label>Logradouro</label>
            <input type="text" value={logradouro} onChange={(e) => setLogradouro(e.target.value)} readOnly />
          </div>
          <div>
            <label>Número</label>
            <input type="text" value={numero} onChange={(e) => setNumero(e.target.value)} />
          </div>
          <div>
            <label>Complemento</label>
            <input type="text" value={complemento} onChange={(e) => setComplemento(e.target.value)} />
          </div>
        </div>
      </form>
      <ModalSucesso
        isOpen={modalSucessoCadastroAberto}
        mensagem={`Cliente ${nome} ${clienteId ? 'atualizado' : 'cadastrado'} com sucesso`}
      />
    </>
  );
};

export default CadastroClienteFrom;
