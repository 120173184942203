import axios from 'axios';

// LOCALHOST
// const api = axios.create({
//   baseURL: 'http://localhost:3333/api',
//   headers: {
//     'Content-Type': 'application/json',
//   }
// });

// NA MESMA REDE
// const api = axios.create({
//   baseURL: 'https://195.200.1.239:3333/api',
//   headers: {
//     'Content-Type': 'application/json',
//   }
// });

// PRODUÇÃO
const api = axios.create({
  baseURL: 'https://cortae.agenciabesse.com.br/api',
  headers: {
    'Content-Type': 'application/json',
  }
});

export default api;
