import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import ModalSucesso from '../modais/ModalSucesso';

const RegistroBarbeiroForm = ({ barbeiroId }) => {
  const [nome, setNome] = useState('');
  const [sobre, setSobre] = useState('');
  const [email, setEmail] = useState('');
  const [imagem, setImagem] = useState(null);
  const [imagemPreview, setImagemPreview] = useState('');
  const [errors, setErrors] = useState({});
  const [modalSucessoCadastroAberto, setModalSucessoCadastroAberto] = useState(false);

  useEffect(() => {
    if (barbeiroId) {
      const fetchBarbeiroData = async () => {
        try {
          const response = await api.post('/barbeiro/id', { barbeiroId });
          const barbeiroData = response.data.barbeiro;
          const emailBarbeiro = response.data.emailUsuario;
          setNome(barbeiroData.nome || '');
          setSobre(barbeiroData.sobre || '');
          setEmail(emailBarbeiro || '');

          if (barbeiroData.imagem) {
            setImagemPreview(`http://localhost:3333/uploads/${barbeiroData.imagem}`);
          }
        } catch (error) {
          console.error('Erro ao carregar dados do barbeiro:', error);
        }
      };
      fetchBarbeiroData();
    }
  }, [barbeiroId]);

  const registrar = async (e) => {
    e.preventDefault();

    const nomeExiste = (await api.post('/buscarNomeBarbeiro', {nome})).data;
    console.log('nome existe?', nomeExiste);
    const formData = new FormData();
    formData.append('nome', nome);
    formData.append('sobre', sobre);
    formData.append('email', email);

    if (imagem) {
      formData.append('imagem', imagem);
    }

    if (validarFormulario(nomeExiste)) {
      if (barbeiroId) {
        formData.append('barbeiroId', barbeiroId);
        await api.put('/atualizarBarbeiro', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        await api.post('/cadastroBarbeiro', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }
      setModalSucessoCadastroAberto(true);
      setTimeout(() => {
        fecharModalSucessoCadastro();
      }, 2500);
    } else {
      console.error('Erro ao registrar barbeiro:');
    }
  };

  const validarFormulario = (nomeExiste) => {
    const newErrors = {};

    const nomePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
    if (!nomePattern.test(nome)) {
      newErrors.nome = 'Por favor, insira um nome válido.';
    } else if(nomeExiste){
      newErrors.nome = 'Já existe um barbeiro com esse nome.';
    }

    if (!sobre) {
      newErrors.sobre = 'Por favor, insira uma descrição sobre o barbeiro.';
    }

    const emailPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    if (!emailPattern.test(email)) {
      newErrors.email = 'Por favor, insira um e-mail válido.';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const uploadImagem = (e) => {
    const file = e.target.files[0];
    setImagem(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagemPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagemPreview('');
    }
  };

  const fecharModalSucessoCadastro = () => {
    setModalSucessoCadastroAberto(false);
    window.location.reload();
  }

  return (
    <>
      <form id="form-registro-barbeiro" onSubmit={registrar}>
        <div>
          <label>Nome *</label>
          <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
          {errors.nome && <p style={{ color: 'red' }}>{errors.nome}</p>}
        </div>
        <div>
          <label>Sobre *</label>
          <input type="text" value={sobre} onChange={(e) => setSobre(e.target.value)} />
          {errors.sobre && <p style={{ color: 'red' }}>{errors.sobre}</p>}
        </div>
        <div>
          <label>Email *</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
        </div>
        <div>
          <label>Imagem</label>
          <input type="file" onChange={uploadImagem} />
          {imagemPreview && (
            <div>
              <img src={imagemPreview} alt="Imagem Preview" style={{ width: '100px', height: '100px', marginTop: '10px' }} />
            </div>
          )}
        </div>
      </form>
      <ModalSucesso
        isOpen={modalSucessoCadastroAberto}
        mensagem={`Barbeiro ${nome} ${barbeiroId ? 'atualizado' : 'cadastrado'} com sucesso`}
      />
    </>
  );
};

export default RegistroBarbeiroForm;
