import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import ModalSucesso from '../modais/ModalSucesso';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import '../../assets/styles/registroAgendamento.css';
import { getTimeZone } from '../../services/controller/definicoesGeraisController';
import moment from 'moment-timezone';

const FormEdicaoAgendamento = ({ textoBotao, clientes, barbeiros, dataSelecaoFiltro, agendamentoId }) => {
  const [clienteSelecionado, setClienteSelecionado] = useState('');
  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [barbeiroSelecionado, setBarbeiroSelecionado] = useState(null);
  const [servicosBarbeiro, setServicosBarbeiro] = useState([]);
  const [diaSelecionado, setDiaSelecionado] = useState(dataSelecaoFiltro);
  const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);
  const [horarioSelecionado, setHorarioSelecionado] = useState(null);
  const [dataAgendamento, setDataAgendamento] = useState(null);
  const [modalSucessoCadastroAberto, setModalSucessoCadastroAberto] = useState(false);
  const [timezone, setTimezone] = useState('');

  useEffect(() => {
    const fetchAgendamentoData = async () => {
      if (!agendamentoId) return;
      try {
        const response = await api.post(`/agendamento/id`, { agendamentoId });
        const timezoneData = await getTimeZone();
        setTimezone(timezoneData.timezone);

        const clienteRetorno = response.data.cliente;
        const barbeiroRetorno = response.data.barbeiro;
        const servicosRetorno = response.data.servicos;
        const idsServicos = servicosRetorno.map(servico => servico.id);
        const barbeiroId = barbeiroRetorno.id;
        
        const servicosBarbeiroSelecionadoData = await api.post('/barbeiroServicos', { barbeiroId });
        setServicosBarbeiro(servicosBarbeiroSelecionadoData.data);

        const dataAgendada = moment(response.data.data).utcOffset(timezoneData.timezone);
        const dataFormatada = dataAgendada.format('YYYY-MM-DD');
        setClienteSelecionado(clienteRetorno.id);
        setBarbeiroSelecionado(barbeiroRetorno.id);
        setServicosSelecionados(idsServicos);
        setDiaSelecionado(dataFormatada);
        
        setHorarioSelecionado(dataAgendada.format('HH:mm'));
        setDataAgendamento(dataAgendada.format('YYYY-MM-DD HH:mm:ssZ'));

      } catch (error) {
        console.error('Erro ao carregar dados do agendamento:', error);
      }
    };
    fetchAgendamentoData();
  }, [agendamentoId]);

  useEffect(() => {
    const fetchHorariosDisponiveis = async () => {
      if (diaSelecionado && servicosSelecionados && barbeiroSelecionado && agendamentoId) {
        try {
          const horariosDisponiveis = await api.post(`/horariosDisponiveisEdicao`, {
            diaSelecionado, servicosSelecionados, barbeiroSelecionado, agendamentoId
          });
          setHorariosDisponiveis(horariosDisponiveis.data);
        } catch (error) {
          console.error('Erro ao carregar dados do agendamento:', error);
        }
      }
    };
    fetchHorariosDisponiveis();
  }, [diaSelecionado, servicosSelecionados, barbeiroSelecionado, agendamentoId]);

  const registrar = async (e) => {
    e.preventDefault();
    try {
      await api.post('/atualizarAgendamento', {
        agendamentoId,
        clienteSelecionado,
        barbeiroSelecionado,
        servicosSelecionados,
        dataAgendamento
      });
      setModalSucessoCadastroAberto(true);
    } catch (error) {
      console.error('Erro ao registrar barbeiro:', error);
    }
  };

  const selecionarBarbeiro = async (barbeiroId) => {
    setBarbeiroSelecionado(barbeiroId);
    const servicosBarbeiroData = await api.post('/barbeiroServicos', { barbeiroId });
    setServicosSelecionados([]);
    setHorarioSelecionado(null);
    setServicosBarbeiro(servicosBarbeiroData.data);
  };

  const selecionarServico = (servicoId) => {
    setServicosSelecionados((prevSelecionados) =>
      prevSelecionados.includes(servicoId)
        ? prevSelecionados.filter((id) => id !== servicoId)
        : [...prevSelecionados, servicoId]
    );
    setHorarioSelecionado(null);
  };

  const selecionarHorario = (horario) => {
    const horarioCompleto = moment(`${diaSelecionado} ${horario}`).tz(timezone);
    setHorarioSelecionado(horarioCompleto.format('HH:mm'));
    setDataAgendamento(horarioCompleto.format('YYYY-MM-DD HH:mm:ssZ'));
  };

  const fecharModalSucessoCadastro = () => {
    setModalSucessoCadastroAberto(false);
    window.location.reload();
  };

  return (
    <>
      <form id='form-edicao-agendamento' onSubmit={registrar}>
        <div>
          <label>Selecione o cliente</label>
          <select
            value={clienteSelecionado}
            onChange={(e) => setClienteSelecionado(e.target.value)} disabled>
            {clientes.map((cliente) => (
              <option key={cliente.id} value={cliente.id}>
                {cliente.nome}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Selecione o barbeiro</label>
          <div className="barbeiros-container">
            {barbeiros.map((barbeiro) => (
              <div
                key={barbeiro.id}
                className={`barbeiro-card ${barbeiroSelecionado === barbeiro.id ? 'selecionado' : ''}`}
                onClick={() => selecionarBarbeiro(barbeiro.id)}
              >
                <div className="foto-circulo">
                  <FontAwesomeIcon icon={faUser} size="3x" />
                </div>
                <p>{barbeiro.nome}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <label>Selecione os serviços</label>
          <div className="servicos-container">
            {servicosBarbeiro.map((servico) => (
              <div
                key={servico.id}
                className={`servico-card ${servicosSelecionados.includes(servico.id) ? 'selecionado' : ''}`}
                onClick={() => selecionarServico(servico.id)}
                style={{
                  background: servicosSelecionados.includes(servico.id) ? '#e0e0e0' : '',
                }}
              >
                <input
                  type="checkbox"
                  checked={servicosSelecionados.includes(servico.id)}
                  onClick={(event) => event.stopPropagation()}
                  onChange={() => selecionarServico(servico.id)}
                  style={{ marginRight: '8px' }}
                />
                <p>{servico.nome}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <label>Selecione o dia</label>
          <input
            type="date"
            value={diaSelecionado}
            onChange={(e) => setDiaSelecionado(e.target.value)}
          />
        </div>
        <div>
          <label>Selecione o horário</label>
          <div className="horarios-container">
            {horariosDisponiveis.map((horario) => (
              <div
                key={horario}
                className={`horario-card ${horarioSelecionado === horario ? 'selecionado' : ''}`}
                onClick={() => selecionarHorario(horario)}
                style={{
                  opacity: horarioSelecionado === horario || horarioSelecionado === null ? 1 : 0.2,
                }}
              >
                <FontAwesomeIcon icon={faClock} />
                <p>{horario}</p>
              </div>
            ))}
          </div>
        </div>
      </form>
      <ModalSucesso
        isOpen={modalSucessoCadastroAberto}
        titulo="Sucesso!"
        mensagem={`Agendamento dia ${dataAgendamento} editado com sucesso`}
        acaoConfirmar={fecharModalSucessoCadastro}
      />
    </>
  );
};

export default FormEdicaoAgendamento;
