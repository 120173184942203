import { useState } from 'react';
import '../assets/styles/componentes.css';

const BotaoFlutuante = () => {
  const [menuVisivel, setMenuVisivel] = useState(false);

  const estadoMenu = () => {
    setMenuVisivel(!menuVisivel);

    const sideMenu = document.querySelector('.divPrincipalSideMenu');
    const linha1 = document.querySelector('.linha1');
    const linha2 = document.querySelector('.linha2');
    const linhaparadas = document.querySelectorAll('.linhaparada');

    if (!menuVisivel) {
      sideMenu.style.transform = 'translateX(0)';
      sideMenu.style.opacity = '1';
      linha1.style.transform = 'rotate(45deg)';
      linha1.style.transformOrigin = 'center';
      linha2.style.transform = 'rotate(-45deg)';
      linha2.style.transformOrigin = 'center';

      linhaparadas.forEach(lp => {
        lp.style.opacity = '0';
      });
    } else {
      sideMenu.style.transform = 'translateX(-100%)';
      sideMenu.style.opacity = '0';
      linha1.style.transform = 'rotate(0deg)';
      linha2.style.transform = 'rotate(0deg)';

      linhaparadas.forEach(lp => {
        lp.style.opacity = '1';
      });
    }
    sideMenu.style.transition = 'transform 0.5s ease, opacity 0.5s ease';
    linha1.style.transition = 'transform 0.5s ease';
    linha2.style.transition = 'transform 0.5s ease';
  };

  return (
    <>
      <button id="botaoFlutuante" onClick={estadoMenu}>
        <div className='linhaparada'></div>
        <div className='linhasDinamicas linha1'></div>
        <div className='linhasDinamicas linha2'></div>
        <div className='linhaparada'></div>
      </button>
    </>
  );
}

export default BotaoFlutuante;
