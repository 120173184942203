import { jwtDecode } from "jwt-decode";
import api from "../services/api";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Carregamento from "../components/Carregamento";

const isTokenValid = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp - currentTime > 0)
      return true
    else
      return false
  } catch (error) {
    return false;
  }
};

export const dadosUsuario = async () => {
  const dadosLocais = localStorage.getItem('usuarioAtual');
  if (dadosLocais) {
    const dadosDesencriptados = await api.post('/desencriptar', { dadosLocais });
    const dados = JSON.parse(dadosDesencriptados.data);
    return dados;
  }
}

export const isLoggedIn = () => {
  const token = localStorage.getItem('token');
  return !!token && isTokenValid(token);
};

export const RotaAdmin = ({ children }) => {
  const [carregando, setCarregando] = useState(true);
  const [autorizado, setAutorizado] = useState(false);
  const [funcao, setFuncao] = useState('');

  useEffect(() => {
    const checkAuth = async () => {
      const loginOk = isLoggedIn();
      const dados = await dadosUsuario();
      if (loginOk && dados) {
        setFuncao(dados.funcao);
        setAutorizado(true);
        setCarregando(false);
      }
      if (!loginOk) setCarregando(false);
    };
    checkAuth();
  }, []);

  if (carregando) {
    return <Carregamento />
  }

  if (funcao === 'Administrador' && autorizado) {
    return children;
  }

  if (funcao !== 'Administrador' && autorizado) {
    return <Navigate to="/login" />;
  }

  return autorizado ? children : <Navigate to="/login" />;
};

export const RotaBarbeiroAdmin = ({ children }) => {
  const [carregando, setCarregando] = useState(true);
  const [autorizado, setAutorizado] = useState(false);
  const [funcao, setFuncao] = useState('');

  useEffect(() => {
    const checkAuth = async () => {
      const loginOk = isLoggedIn();
      const dados = await dadosUsuario();
      if (loginOk && dados) {
        setFuncao(dados.funcao);
        setAutorizado(true);
        setCarregando(false);
      }
      if (!loginOk) setCarregando(false);

    };
    checkAuth();
  }, []);

  if (carregando) {
    return <Carregamento />
  }

  if ((funcao === 'Barbeiro' || funcao === 'Administrador') && autorizado) {
    return children;
  }

  return <Navigate to="/login" />;
};

export const RotaCliente = ({ children }) => {
  const [carregando, setCarregando] = useState(true);
  const [funcao, setFuncao] = useState('');
  const [autorizado, setAutorizado] = useState(false);
  useEffect(() => {
    const checkAuth = async () => {
      const loginOk = isLoggedIn();
      const dados = await dadosUsuario();
      if (loginOk && dados) {
        setFuncao(dados.funcao);
        setAutorizado(true);
        setCarregando(false);
      }
      if (!loginOk) setCarregando(false);
    };
    checkAuth();
  }, []);
  console.log('logado', autorizado)

  if (carregando) {
    return <Carregamento />;
  }
  if (funcao === 'Cliente' && autorizado) {
    return children;
  }

  if (funcao !== 'Cliente' && autorizado) {
    return <Navigate to="/dashboard" />;
  }
  return <Navigate to="/login" />;
};

export const RotaPublica = ({ children }) => {
  const [carregando, setCarregando] = useState(true);
  const [publico, setPublico] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const loginOk = isLoggedIn();
      setPublico(!loginOk);
      setCarregando(false);
    };
    checkAuth();
  }, []);

  if (carregando) {
    return <Carregamento />
  }

  return publico ? children : <Navigate to="/minha-conta" />;
};