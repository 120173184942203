import React, { useState, useEffect } from "react";
import SideMenu from "../components/SideMenu";
import '../assets/styles/padraoMinhaConta.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faCalendarXmark, faEdit, faPlus, faScissors, faSortDesc, faSortUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import ModalCadastroBarbeiro from "../components/modais/ModalCadastroBarbeiro";
import ModalConfirmacaoAcao from '../components/modais/ModalConfirmacaoAcao';
import ModalSucesso from '../components/modais/ModalSucesso';
import ModalBabeiroServico from '../components/modais/ModalBarbeiroServicos';
import ModalHorarioBarbeiro from '../components/modais/ModalHorarioBarbeiro';
import ModalRestricaoHorarioBarbeiro from "../components/modais/ModalRestricaoHorario";
import { ordenarBarbeiros, desativarBarbeiro, getBarbeirosAtivos, buscarBarbeiro } from "../services/controller/barbeiroController";
import { getServicos } from "../services/controller/servicoController";
import BotaoFlutuante from '../components/botaoFlutuante';

const PageBarbeiro = () => {
    const [barbeiros, setBarbeiros] = useState([]);
    const [servicos, setServicos] = useState([])
    const [error, setError] = useState(null);
    const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
    const [modalEdicaoAberto, setModalEdicaoAberto] = useState(false);
    const [barbeiroSelecionadoId, setBarbeiroSelecionadoId] = useState(null);
    const [modalConfirmacaoAberto, setModalConfirmacaoAberto] = useState(false);
    const [barbeiroParaExcluir, setBarbeiroParaExcluir] = useState(null);
    const [nomeBarbeiro, setNomeBarbeiro] = useState('');
    const [modalSucessoExclusaoAberto, setModalSucessoExclusaoAberto] = useState(false);
    const [modalBarbeiroServicoAberto, setModalBarbeiroServicoAberto] = useState(false);
    const [modalHorariosBarbeiro, setModalHorariosBarbeiro] = useState(false);
    const [modalRestricaoHorariosBarbeiro, setModalRestricaoHorariosBarbeiro] = useState(false);

    useEffect(() => {
        const fetchBarbeiros = async () => {
            try {
                const barbeiroData = await getBarbeirosAtivos();
                setBarbeiros(barbeiroData);
                const servicosData = await getServicos();
                setServicos(servicosData);
            } catch (error) {
                setError(error.message);
            }
        };
        fetchBarbeiros();
    }, []);

    const filtroBusca = async (e) => {
        const busca = e.target.value;
        const barbeirosRetorno = await buscarBarbeiro(busca);
        setBarbeiros(barbeirosRetorno);
    };

    const ordenacao = (ordem, criterio) => {
        const barbeirosOrdenados = ordenarBarbeiros(barbeiros, criterio, ordem);
        setBarbeiros(barbeirosOrdenados);
    };

    const fecharModalCadastro = () => {
        setModalCadastroAberto(false);
        setBarbeiroSelecionadoId(null);
    };
    const fecharModalEdicao = () => {
        setModalEdicaoAberto(false);
        setBarbeiroSelecionadoId(null);
    };

    const fecharModalConfirmacao = () => {
        setModalConfirmacaoAberto(false);
    };

    const fecharModalBarbeiroServico = () => {
        setModalBarbeiroServicoAberto(false);
    };

    const fecharModalHorariosBarbeiro = () => {
        setModalHorariosBarbeiro(false);
    };

    const fecharModalRestricaoHorarios = () => {
        setModalRestricaoHorariosBarbeiro(false);
    };

    const fecharModalSucesso = () => {
        setModalSucessoExclusaoAberto(false);
        window.location.reload();
    }

    const confirmarExclusao = async () => {
        if (barbeiroParaExcluir) {
            try {
                await desativarBarbeiro(barbeiroParaExcluir, setBarbeiros, setError);
                fecharModalConfirmacao();
                setModalSucessoExclusaoAberto(true);
            } catch (error) {
                setError(error.message);
                fecharModalConfirmacao();
            }
        }
    };

    if (error) {
        return <p>Erro: {error}</p>;
    }

    return (
        <>
            <SideMenu />
            <BotaoFlutuante />
            <div className="divPrincipalMinhaConta">
                <ModalRestricaoHorarioBarbeiro
                    isOpen={modalRestricaoHorariosBarbeiro}
                    titulo={`Restrição de horários do Barbeiro`}
                    barbeiroId={barbeiroSelecionadoId}
                    textoBotao={'Salvar restrição de horários do Barbeiro'}
                    onCancel={fecharModalRestricaoHorarios}
                />
                <ModalHorarioBarbeiro
                    isOpen={modalHorariosBarbeiro}
                    titulo={`Horários do Barbeiro`}
                    barbeiroId={barbeiroSelecionadoId}
                    nomeBarbeiro={nomeBarbeiro}
                    textoBotao={'Salvar horários do Barbeiro'}
                    onCancel={fecharModalHorariosBarbeiro}
                />
                <ModalBabeiroServico
                    isOpen={modalBarbeiroServicoAberto}
                    titulo={`Serviços do Barbeiro`}
                    servicos={servicos}
                    barbeiroId={barbeiroSelecionadoId}
                    nomeBarbeiro={nomeBarbeiro}
                    textoBotao={'Salvar Serviços do Barbeiro'}
                    onCancel={fecharModalBarbeiroServico}
                />
                <ModalSucesso
                    isOpen={modalSucessoExclusaoAberto}
                    titulo={`Sucesso!`}
                    mensagem={`Barbeiro ${nomeBarbeiro} excluido com sucesso`}
                    acaoConfirmar={fecharModalSucesso}
                />
                <ModalConfirmacaoAcao
                    isOpen={modalConfirmacaoAberto}
                    titulo={`Excluir ${nomeBarbeiro}?`}
                    mensagem="Você tem certeza que deseja excluir este barbeiro?"
                    acaoCancelar={fecharModalConfirmacao}
                    acaoConfirmar={confirmarExclusao}
                />
                <ModalCadastroBarbeiro
                    isOpen={modalCadastroAberto}
                    titulo={'Novo Barbeiro'}
                    textoBotao={'Adicionar Barbeiro'}
                    onCancel={fecharModalCadastro}
                />
                <ModalCadastroBarbeiro
                    isOpen={modalEdicaoAberto}
                    titulo={'Editar Barbeiro'}
                    textoBotao={'Salvar edição'}
                    barbeiroId={barbeiroSelecionadoId}
                    onCancel={fecharModalEdicao}
                />
                <div className="superiorMinhaConta">
                    <h1>Barbeiros</h1>
                    <button onClick={() => setModalCadastroAberto(true)}>Adicionar Barbeiro</button>
                    <button className="btnAdicionarMobile" onClick={() => setModalCadastroAberto(true)}><FontAwesomeIcon icon={faPlus} /></button>
                </div>
                <div className="filtrosMinhaConta">
                    <input placeholder="buscar barbeiro" onChange={filtroBusca}></input>
                </div>
                <div className="gridMinhaConta">
                    <div className="cabecalhoGridMinhaConta">
                        <div className="celulaComOrdenacao nome">
                            <p>{'Nome'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'nome')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'nome')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        {/* <p className="coluna2">{'Usuario Id'}</p> */}
                        <p className="botoesAcao acoes">{'Ações'}</p>
                    </div>
                    <div className="conteudoGridMinhaConta">
                        {barbeiros.map((barbeiro) => (
                            <div className="linhasGridMinhaConta" key={barbeiro.id}>
                                <p className="celulaComOrdenacao nome">{barbeiro.nome}</p>
                                {/* <p className="coluna2">{barbeiro.usuarioId}</p> */}
                                <div className="botoesAcao">
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ backgroundColor: 'var(--vermelhoLixeira)' }}
                                        onClick={() => {
                                            setModalConfirmacaoAberto(true)
                                            setBarbeiroParaExcluir(barbeiro.id)
                                            setNomeBarbeiro(barbeiro.nome)
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        onClick={() => {
                                            setModalEdicaoAberto(true)
                                            setBarbeiroSelecionadoId(barbeiro.id)
                                        }}
                                        style={{ backgroundColor: 'var(--azulEdicao)' }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faScissors}
                                        onClick={() => {
                                            setNomeBarbeiro(barbeiro.nome)
                                            setModalBarbeiroServicoAberto(true)
                                            setBarbeiroSelecionadoId(barbeiro.id)
                                        }}
                                        style={{ backgroundColor: 'var(--servicos)' }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faCalendarCheck}
                                        onClick={() => {
                                            setNomeBarbeiro(barbeiro.nome)
                                            setModalHorariosBarbeiro(true)
                                            setBarbeiroSelecionadoId(barbeiro.id)
                                        }}
                                        style={{ backgroundColor: 'var(--horarioAtendimento)' }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faCalendarXmark}
                                        onClick={() => {
                                            setModalRestricaoHorariosBarbeiro(true)
                                            setBarbeiroSelecionadoId(barbeiro.id)
                                        }}
                                        style={{ backgroundColor: 'var(--restricaoHorario)' }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageBarbeiro;
