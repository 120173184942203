import api from "../api"


export const getAgendamentos = async () => {
    try {
        const response = await api.get('/agendamentosAtivos');
        return response.data;
    }
    catch (error) {
        console.error('Erro ao buscar os Agendamentos:', error);
        throw error;
    }
};
export const getAgendamentosPendentes = async () => {
    try {
        const response = await api.get('/agendamentosPendentes');
        return response.data;
    }
    catch (error) {
        console.error('Erro ao buscar os Agendamentos:', error);
        throw error;
    }
};

export const getAgendamentoClientes = async (usuarioId) => {
    try {
        const response = await api.post('/agendametosClientes', { usuarioId });
        return response.data.agendamentoRetorno;
    }
    catch (error) {
        console.error('Erro ao buscar os Agendamentos:', error);
        throw error;
    }
};

export const getAgendamentosBarbeirosEClientes = async () => {
    try {
        const response = await api.get('/agendamentosBarbeirosClientes');
        return response.data;
    }
    catch (error) {
        console.error('Erro ao buscar os Agendamentos:', error);
        throw error;
    }
};

export const getInfosAgendamento =  async (agendamentoId) =>{
    try{
        const response = await api.post('/infosAgendamento',{agendamentoId});
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar os Agendamentos:', error);
        throw error;
    }
};

export const aprovarAgendamento = async (agendamentoId, status, setAgendamentosPendentes) => {
    try {
        await api.post('/aprovarOuNegarAgendamento', { agendamentoId, status });
        const agendamentoData = await getAgendamentosPendentes();
        setAgendamentosPendentes(agendamentoData.pendentes);
    }
    catch (error) {
        console.error('Erro ao aprovar o agendamento:', error);
        throw error;
    }
};
export const desativarAgendamento = async (idAgendamento, setAgendamento, setError) => {
    try {
        await api.put(`/desativarAgendamento/`, {
            idAgendamento
        });
        const agendamentoData = await getAgendamentos();
        setAgendamento(agendamentoData);
    } catch (error) {
        setError('Erro ao desativar Agendamento:', error);
    }
};
export const buscarAgendamento = async (busca) => {
    try {
        const response = await api.post('/agendamentosBuscados', {
            busca
        });
        return response.data;
    } catch (error) {
        console.log('erro');
    }
};
export const ordenarServicos = (servicos, criterio, ordem = 'asc') => {
    return [...servicos].sort((a, b) => {
        if (a[criterio] < b[criterio]) return ordem === 'asc' ? -1 : 1;
        if (a[criterio] > b[criterio]) return ordem === 'asc' ? 1 : -1;
        return 0;
    });
};

export const obterTempoDoServico = async (idAgendamento) => {
    try {
        const response = await api.post('/duracaoAgendamento', { idAgendamento });
        return response.data.tempoTotal;
    } catch (error) {
        console.error('Erro ao obter o tempo do serviço:', error);
        throw error;
    }
};