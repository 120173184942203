import { useEffect, useState } from "react";
import api from "../../services/api";
import ModalSucesso from '../modais/ModalSucesso';

const BarbeiroServico = ({ textoBotao, servicos, barbeiroId, nomeBarbeiro }) => {
  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [modalSucessoCadastroAberto, setModalSucessoCadastroAberto] = useState(false);

  useEffect(() => {
    const fetchBarbeiroServicos = async () => {
      try {
        const response = await api.post(`/barbeiroServicos`, {
          barbeiroId
        });
        const idsServicos = response.data.map(servico => servico.id);
        setServicosSelecionados(idsServicos);
      } catch (error) {
        console.error('Erro ao carregar os serviços do barbeiro:', error);
      }
    };
    fetchBarbeiroServicos();
  }, [barbeiroId]);


  const capturaServicosSelecionados = (e) => {
    const servicoId = parseInt(e.target.value, 10);

    setServicosSelecionados(prevSelecionados =>
      e.target.checked
        ? [...prevSelecionados, servicoId]
        : prevSelecionados.filter(id => id !== servicoId)
    );
  };

  const registrar = async (e) => {
    e.preventDefault();
    try {
      await api.post('/cadastrarOuAtualizarBarbeiroServicos', {
        barbeiroId,
        servicos: servicosSelecionados
      });
      setModalSucessoCadastroAberto(true);
    } catch (error) {
      console.error('Erro ao registrar os serviços do barbeiro:', error);
    }
  };
  const fecharModalSucessoCadastro = () => {
    setModalSucessoCadastroAberto(false);
    window.location.reload();
  }

  return (
    <>
      <form id="form-registro-barbeiroServico" onSubmit={registrar}>
        {servicos.map((servico) => (
          <div key={servico.id}>
            <label>
              <input
                type="checkbox"
                value={servico.id}
                checked={servicosSelecionados.includes(servico.id)}
                onChange={capturaServicosSelecionados}
              />
              {servico.nome}
            </label>
          </div>
        ))}
      </form>
      <ModalSucesso
        isOpen={modalSucessoCadastroAberto}
        titulo="Sucesso!"
        mensagem={`Serviço do barbeiro ${nomeBarbeiro} ${barbeiroId ? 'atualizado' : 'cadastrado'} com sucesso`}
        acaoConfirmar={fecharModalSucessoCadastro}
      />
    </>
  );
};

export default BarbeiroServico;
