import { useEffect, useState } from "react";
import { getClientes, ordenarClientes, desativarCliente, buscarCliente } from "../services/controller/clienteController";
import SideMenu from "../components/SideMenu";
import ModalCadastroCliente from "../components/modais/ModalCadastroCliente";
import ModalConfirmacaoAcao from '../components/modais/ModalConfirmacaoAcao';
import ModalSucesso from '../components/modais/ModalSucesso';
import '../assets/styles/padraoMinhaConta.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDesc, faTrash, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import BotaoFlutuante from '../components/botaoFlutuante';


const PageUsuario = () => {
    const [clientes, setClientes] = useState([]);
    const [error, setError] = useState(null);
    const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
    const [modalEdicaoAberto, setModalEdicaoAberto] = useState(false);
    const [clienteSelecionadoId, setClienteSelecionadoId] = useState(null);
    const [modalConfirmacaoAberto, setModalConfirmacaoAberto] = useState(false);
    const [clienteParaExcluir, setClienteParaExcluir] = useState(null);
    const [nomeClienteParaExcluir, setNomeClienteParaExcluir] = useState('');
    const [modalSucessoExclusaoAberto, setModalSucessoExclusaoAberto] = useState(false);

    useEffect(() => {
        const fetchClientes = async () => {
            try {
                const userData = await getClientes();
                setClientes(userData);
            } catch (error) {
                setError(error.message);
            }
        };
        fetchClientes();
    }, []);

    const filtroBusca = async (e) => {
        const busca = e.target.value;
        const clientesRetorno = await buscarCliente(busca);
        setClientes(clientesRetorno);
    };

    const ordenacao = (ordem, criterio) => {
        const clientesOrdenados = ordenarClientes(clientes, criterio, ordem);
        setClientes(clientesOrdenados);
    };

    const fecharModalCadastro = () => {
        setModalCadastroAberto(false);
        setClienteSelecionadoId(null);
    };
    const fecharModalEdicao = () => {
        setModalEdicaoAberto(false);
        setClienteSelecionadoId(null);
    };

    const fecharModalConfirmacao = () => {
        setModalConfirmacaoAberto(false);
    };

    const confirmarExclusao = async () => {
        if (clienteParaExcluir) {
            try {
                await desativarCliente(clienteParaExcluir, setClientes, setError);
                fecharModalConfirmacao();
                setModalSucessoExclusaoAberto(true);
                setTimeout(() => {
                    setModalSucessoExclusaoAberto(false);
                }, 2000);
            } catch (error) {
                setError(error.message);
                fecharModalConfirmacao();
            }
        }
    };

    if (error) {
        return <p>Erro: {error}</p>;
    }

    return (
        <>
            <SideMenu /><BotaoFlutuante/>
            <div className="divPrincipalMinhaConta">
                <ModalSucesso
                    isOpen={modalSucessoExclusaoAberto}
                    mensagem={`Cliente ${nomeClienteParaExcluir} excluido com sucesso`}
                />
                <ModalConfirmacaoAcao
                    isOpen={modalConfirmacaoAberto}
                    titulo={`Excluir ${nomeClienteParaExcluir}?`}
                    mensagem="Você tem certeza que deseja excluir este cliente?"
                    acaoCancelar={fecharModalConfirmacao}
                    acaoConfirmar={confirmarExclusao}
                />
                <ModalCadastroCliente
                    isOpen={modalCadastroAberto}
                    titulo="Novo Cliente"
                    textoBotao={'Adicionar Cliente'}
                    onCancel={fecharModalCadastro}
                />
                <ModalCadastroCliente
                    isOpen={modalEdicaoAberto}
                    titulo="Editar Cliente"
                    textoBotao={'Salvar Edição'}
                    clienteId={clienteSelecionadoId}
                    onCancel={fecharModalEdicao}
                />
                <div className="superiorMinhaConta">
                    <h1>Clientes</h1>
                    <button onClick={() => setModalCadastroAberto(true)}>Adicionar Clientes</button>
                    <button className="btnAdicionarMobile" onClick={() => setModalCadastroAberto(true)}><FontAwesomeIcon icon={faPlus}/></button>
                </div>
                <div className="filtrosMinhaConta">
                    <input placeholder="buscar cliente" onChange={filtroBusca}></input>
                </div>
                <div className="gridMinhaConta">
                    <div className="cabecalhoGridMinhaConta">
                        <div className="celulaComOrdenacao nome">
                            <p>{'Nome'}</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FontAwesomeIcon
                                    icon={faSortUp}
                                    onClick={() => ordenacao('asc', 'nome')}
                                    style={{ marginBottom: '-3px' }}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    onClick={() => ordenacao('desc', 'nome')}
                                    style={{ marginTop: '-3px' }}
                                />
                            </div>
                        </div>
                        <p className="celulaComOrdenacao" >{'Telefone'}</p>
                        <p className="coluna2 acoes" >{'CEP'}</p>
                        <p className="botoesAcao acoes" >{'Ações'}</p>
                    </div>
                    <div className="conteudoGridMinhaConta">
                        {clientes.map((cliente) => (
                            <div className="linhasGridMinhaConta" key={cliente.id}>
                                <p className="celulaComOrdenacao nome">{cliente.nome}</p>
                                <p className="celulaComOrdenacao">{cliente.telefone}</p>
                                <p className="coluna2 acoes">{cliente.cep}</p>
                                <div className="botoesAcao">
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ backgroundColor: 'var(--vermelhoLixeira)' }}
                                        onClick={() => {
                                            setModalConfirmacaoAberto(true)
                                            setClienteParaExcluir(cliente.id)
                                            setNomeClienteParaExcluir(cliente.nome)
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        onClick={() => {
                                            setModalEdicaoAberto(true)
                                            setClienteSelecionadoId(cliente.id)
                                        }}
                                        style={{ backgroundColor: 'var(--azulEdicao)' }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageUsuario;
