import { useEffect, useState } from 'react';
import BotaoFlutuante from '../components/botaoFlutuante';
import SideMenu from '../components/SideMenu';
import ModalCadastroAgendamento from '../components/modais/ModalCadastroAgendamento';
import { getAgendamentoClientes } from '../services/controller/agendamentoController';
import { getBarbeirosAtivos } from '../services/controller/barbeiroController';
import moment from 'moment';
import { dadosUsuario } from '../utils/auth';
import { getClientes } from '../services/controller/clienteController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const AgendamentosCliente = () => {
  const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState([]);
  const [barbeiros, setBarbeiros] = useState([]);
  const [agendamentosClientes, setAgendamentosClientes] = useState([]);

  const diaAtual = moment().format('YYYY-MM-DD');

  useEffect(() => {
    const fetchBarbeirosEAgendamentos = async () => {
      try {
        const dados = await dadosUsuario();
        const barbeiroData = await getBarbeirosAtivos();
        setBarbeiros(barbeiroData);
        const clientesData = await getClientes();
        setClientes(clientesData);
        const idUsuarioData = dados.id;
        const agendamentosCliente = await getAgendamentoClientes(idUsuarioData);
        setAgendamentosClientes(agendamentosCliente);
        const idClienteAtual = dados.cliente;
        setClienteSelecionado(idClienteAtual);
      } catch (error) {
        console.log('erro ao buscar os agendamentos do cliente')
      }
    };

    fetchBarbeirosEAgendamentos();

    const intervalId = setInterval(fetchBarbeirosEAgendamentos, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const fecharModalCadastro = () => {
    setModalCadastroAberto(false);
  };

  const encontrarBarbeiroPorId = (id) => {
    const barbeiro = barbeiros.find((barbeiro) => barbeiro.id === id);
    return barbeiro ? barbeiro.nome : 'Desconhecido';
  };

  const obterStatusAgendamento = (status) => {
    const statusMap = {
      1: 'Aguardando aprovação',
      2: 'Confirmado',
      3: 'Cancelado',
      4: 'Negado',
      5: 'Em andamento',
      6: 'Concluído',
    };
    return statusMap[status] || 'Desconhecido';
  };

  return (
    <>
      <BotaoFlutuante />
      <SideMenu />
      <ModalCadastroAgendamento
        isOpen={modalCadastroAberto}
        titulo={'Novo Agendamento'}
        textoBotao={'Adicionar Agendamento'}
        clientes={clientes}
        barbeiros={barbeiros}
        diaSelecionado={diaAtual}
        clienteSelecionado={clienteSelecionado}
        onCancel={fecharModalCadastro}
      />
      <div className="divPrincipalMinhaConta">
        <div className="superiorMinhaConta">
          <h1>Agendamentos</h1>
          <div className='botoesSuperior'>
            <button style={{ display: 'block', aspectRatio: '1' }} onClick={() => setModalCadastroAberto(true)}><FontAwesomeIcon icon={faPlus} /></button>
          </div>
        </div>
        {agendamentosClientes.length > 0 ? (
          <div className="agendamentosContainer">
            {agendamentosClientes.map((agendamento) => (
              <div key={agendamento.id} className="agendamentoItemCliente">
                <p>Data: {moment(agendamento.dataAgendada).format('DD/MM/YYYY')}</p>
                <p>Barbeiro: {encontrarBarbeiroPorId(agendamento.barbeiro_id)}</p>
                <p>Preço: {agendamento.preco}</p>
                <p>Status: {obterStatusAgendamento(agendamento.status)}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>Nenhum agendamento encontrado.</p>
        )}
      </div>
    </>
  );
};

export default AgendamentosCliente;
