import '../../assets/styles/Modal.css';
import Calendar from '../../components/Calendario';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faUser } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

const ModalFiltrosEBotoes = ({ isOpen, titulo, onCancel, barbeiros, barbeirosSelecionados, setBarbeirosSelecionados, setDiaSelecionado, onTodosClick, onPendentesClick, onNovoClick }) => {

  const barbeirosSelecao = (barbeiroId) => {
    setBarbeirosSelecionados((prevSelecionados) =>
      prevSelecionados.includes(barbeiroId)
        ? prevSelecionados.filter((id) => id !== barbeiroId)
        : [...prevSelecionados, barbeiroId]
    );
  };

  const getCorBarbeiro = (barbeiroId) => {
    const coresFixas = ['#083D77', '#1098f7', '#48a9a6', '#0c6bb7', '#2ca1cf'];
    const index = barbeiros.findIndex(barbeiro => barbeiro.id === barbeiroId);
    return coresFixas[index % coresFixas.length];
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className={`modal-filter ${isOpen ? 'open' : ''}`}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className="modal-header">
          <h2>{titulo}</h2>
          <button style={{ backgroundColor: '#ffffff00', border: 'none', fontSize: '30px' }} onClick={onCancel}><FontAwesomeIcon icon={faClose} /></button>
        </div>
        <div className="modal-body">
          <div className="botoesSuperior">
            <button onClick={onTodosClick}>Todos</button>
            <button onClick={onPendentesClick}>Pendentes</button>
            <button onClick={onNovoClick}>Novo</button>
          </div>

          <div className="filtrosAgendamentos">
            <Calendar onDiaSelecionadoChange={setDiaSelecionado} />
            <div className="linhaDivisao"></div>
            {barbeiros.map((barbeiro) => (
              <div
                className="barbeirosFiltro"
                key={barbeiro.id}
                onClick={() => barbeirosSelecao(barbeiro.id)}
                style={{
                  backgroundColor: barbeirosSelecionados.includes(barbeiro.id)
                    ? getCorBarbeiro(barbeiro.id)
                    : '#FFF',
                  color: barbeirosSelecionados.includes(barbeiro.id) ? '#fff' : '#000'
                }}
              >
                <div className="fotoENome">
                  <FontAwesomeIcon icon={faUser} />
                  <p>{barbeiro.nome}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFiltrosEBotoes;
