import '../assets/styles/carregamento.css';
import animationData from '../assets/animations/cortae-loading.json';
import { Player } from '@lottiefiles/react-lottie-player';

const Carregamento = () => {

  return (
    <div className="overlayCarregamento">
      <Player
        autoplay
        loop
        src={animationData}
        style={{ height: '300px', width: '300px', opacity:'0.5' }}
      />
    </div>
  )

}

export default Carregamento;
