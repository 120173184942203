import { useEffect, useState } from "react";
import api from "../../services/api";
import ModalSucesso from '../modais/ModalSucesso';

const ServicoBarbeiro = ({ textoBotao, barbeiros, servicoId, nomeServico }) => {
  const [barbeirosSelecionados, setBarbeirosSelecionados] = useState([]);
  const [modalSucessoCadastroAberto, setModalSucessoCadastroAberto] = useState(false);
  useEffect(() => {
    const fetchBarbeirosServico = async () => {
      try {
        const response = await api.post(`/servicoBarbeiros`, {
          servicoId
        });
        const idsBarbeiros = response.data.map(barbeiro => barbeiro.id);
        setBarbeirosSelecionados(idsBarbeiros);
      } catch (error) {
        console.error('Erro ao carregar os serviços do barbeiro:', error);
      }
    };
    fetchBarbeirosServico();
  }, [servicoId]);


  const capturaBarbeirosSelecionados = (e) => {
    const barbeiroId = parseInt(e.target.value, 10);

    setBarbeirosSelecionados(prevSelecionados =>
      e.target.checked
        ? [...prevSelecionados, barbeiroId]
        : prevSelecionados.filter(id => id !== barbeiroId)
    );
  };

  const registrar = async (e) => {
    e.preventDefault();
    try {
      await api.post('/cadastrarOuAtualizarServicoBarbeiros', {
        servicoId,
        barbeiros: barbeirosSelecionados
      });
      setModalSucessoCadastroAberto(true);
    } catch (error) {
      console.error('Erro ao registrar os serviços do barbeiro:', error);
    }
  };
  const fecharModalSucessoCadastro = () => {
    setModalSucessoCadastroAberto(false);
    window.location.reload();
  }

  return (
    <>
      <form id="form-edicao-servicosBarbeiro" onSubmit={registrar}>
        {barbeiros.map((barbeiro) => (
          <div key={barbeiro.id}>
            <label>
              <input
                type="checkbox"
                value={barbeiro.id}
                checked={barbeirosSelecionados.includes(barbeiro.id)}
                onChange={capturaBarbeirosSelecionados}
              />
              {barbeiro.nome}
            </label>
          </div>
        ))}
      </form>
      <ModalSucesso
        isOpen={modalSucessoCadastroAberto}
        titulo="Sucesso!"
        mensagem={`Barbeiros que executam o serviço ${nomeServico} ${servicoId ? 'atualizado' : 'cadastrado'} com sucesso`}
        acaoConfirmar={fecharModalSucessoCadastro}
      />
    </>
  );
};

export default ServicoBarbeiro;
