import { useEffect, useState } from "react";
import api from "../../services/api";
import ModalSucesso from '../modais/ModalSucesso';
import '../../assets/styles/horariosAtendimento.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

export const diasDaSemanaEnum = {

  0: "Segunda",
  1: "Terça",
  2: "Quarta",
  3: "Quinta",
  4: "Sexta",
  5: "Sábado",
  6: "Domingo"
};

const HorarioBarbeiro = ({ textoBotao, barbeiroId, nomeBarbeiro }) => {
  const [horariosAtendimentoBarbeiro, setHorariosAtendimentoBarbeiro] = useState([]);
  const [horariosGerados, setHorariosGerados] = useState([]);
  const [modalSucessoCadastroAberto, setModalSucessoCadastroAberto] = useState(false);

  useEffect(() => {
    const fetchHorariosBarbeiro = async () => {
      try {
        const response = await api.post(`/horariosBarbeiro`, {
          barbeiroId,
        });
        setHorariosAtendimentoBarbeiro(response.data);
      } catch (error) {
        console.error("Erro ao carregar os horários do barbeiro:", error);
      }
    };
    fetchHorariosBarbeiro();
  }, [barbeiroId]);

  useEffect(() => {
    const fetchTempoJanela = async () => {
      try {
        const response = await api.get("/tempoJanela");
        const tempoJanela = response.data.tempoJanela;
        const horarios = gerarHorarios(tempoJanela);
        setHorariosGerados(horarios);
      } catch (error) {
        console.error("Erro ao carregar o tempoJanela:", error);
      }
    };

    fetchTempoJanela();
  }, []);

  const gerarHorarios = (tempoJanela) => {
    const horarios = [];
    let horaAtual = 6 * 60;
    while (horaAtual < 23 * 60) {
      const horas = Math.floor(horaAtual / 60);
      const minutos = horaAtual % 60;
      const horarioFormatado = `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}:00`;
      horarios.push(horarioFormatado);
      horaAtual += tempoJanela;
    }
    return horarios;
  };

  const alterarHorario = (index, field, value) => {
    const horariosAtualizados = horariosAtendimentoBarbeiro.map((horario, i) =>
      i === index ? { ...horario, [field]: value } : horario
    );
    setHorariosAtendimentoBarbeiro(horariosAtualizados);
  };

  const adicionarNovoHorario = () => {
    setHorariosAtendimentoBarbeiro((prevHorarios) => [
      ...prevHorarios,
      { diaDaSemana: 2, horarioInicio: "", horarioFim: "", ativo: false },
    ]);
  };

  const removerHorario = (index) => {
    setHorariosAtendimentoBarbeiro((prevHorarios) =>
      prevHorarios.filter((_, i) => i !== index)
    );
  };

  const registrar = async (e) => {
    e.preventDefault();
    try {
      await api.post("/cadastrarOuAtualizarHorariosBarbeiro", {
        barbeiroId,
        horariosAtendimento: horariosAtendimentoBarbeiro,
      });
      setModalSucessoCadastroAberto(true);
    } catch (error) {
      console.error("Erro ao registrar os horários do barbeiro:", error);
    }
  };

  const filtrarHorariosDisponiveis = (horarioInicio) => {
    if (!horarioInicio) return horariosGerados;
    const inicioMinutos = parseInt(horarioInicio.split(":")[0]) * 60 + parseInt(horarioInicio.split(":")[1]);
    return horariosGerados.filter(horario => {
      const horarioMinutos = parseInt(horario.split(":")[0]) * 60 + parseInt(horario.split(":")[1]);
      return horarioMinutos > inicioMinutos;
    });
  };
  const fecharModalSucessoCadastro = () => {
    setModalSucessoCadastroAberto(false);
    window.location.reload();
  }
  return (
    <>
      <form id="formHorarios" className="formHorarios" onSubmit={registrar}>
        <div className="tabelaHorarios">
          <div className="superiorHorarios">
            <p className="col1">Ativo</p>
            <p className="col2">Dia da Semana</p>
            <p className="col3">Horário de Início</p>
            <p className="col4">Horário de Fim</p>
            <p className="col5">Remover</p>
          </div>
          {horariosAtendimentoBarbeiro.map((horarioAtendimento, index) => (
            <div className="linhasHorariosAtendimento" key={horarioAtendimento.id ? horarioAtendimento.id : `novo-${index}`}>
              <input
                className="col1"
                type="checkbox"
                checked={horarioAtendimento.ativo}
                onChange={(e) =>
                  alterarHorario(index, "ativo", e.target.checked)
                }
              />
              <select
                className="col2"
                value={horarioAtendimento.diaDaSemana}
                onChange={(e) =>
                  alterarHorario(index, "diaDaSemana", parseInt(e.target.value))
                }
              >
                {Object.keys(diasDaSemanaEnum).map((numero) => (
                  <option key={numero} value={numero}>
                    {diasDaSemanaEnum[numero]}
                  </option>
                ))}
              </select>
              <select
                className="col3"
                value={horarioAtendimento.horarioInicio}
                onChange={(e) =>
                  alterarHorario(index, "horarioInicio", e.target.value)
                }
              >
                <option value=''>Selecione</option>
                {horariosGerados.map((horarioGerado) => (
                  <option key={horarioGerado} value={horarioGerado}>
                    {horarioGerado}
                  </option>
                ))}
              </select>
              <select
                className="col4"
                value={horarioAtendimento.horarioFim}
                onChange={(e) =>
                  alterarHorario(index, "horarioFim", e.target.value)
                }
              >
                <option value=''>Selecione</option>
                {filtrarHorariosDisponiveis(horarioAtendimento.horarioInicio).map((horarioGerado) => (
                  <option key={horarioGerado} value={horarioGerado}>
                    {horarioGerado}
                  </option>
                ))}
              </select>
              <div className="col5">
                <button type="button" className="btnRemover" onClick={() => removerHorario(index)}>
                  <FontAwesomeIcon icon={faMinus} />
                </button>
              </div>
            </div>
          ))}
        </div>
        <button className="btnAdicionarMais" type="button" onClick={adicionarNovoHorario}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </form>
      <ModalSucesso
        isOpen={modalSucessoCadastroAberto}
        titulo="Sucesso!"
        mensagem={`Horarios do barbeiro ${nomeBarbeiro} ${barbeiroId ? 'atualizado' : 'cadastrado'} com sucesso`}
        acaoConfirmar={fecharModalSucessoCadastro}
      />
    </>
  );
};

export default HorarioBarbeiro;
